import React, { useContext, useEffect } from 'react';
import SignUpFormContextProvider, { SignUpFormContext, CreateAccountResponseBody } from './SignUpFormContext';
import { SwitchTransition } from 'react-transition-group';
import FadeTransition from 'shared/components/FadeTransition';
import BusinessForm from './BusinessForm';
import AccountForm from './AccountForm';
import { SuccessPage } from './SuccessPage';
import { makeStyles, Theme, createStyles, Typography, Grid, Container, Paper } from '@material-ui/core';
import Logo from 'giftcards/assets/logo/whitelogo.png';
import { Link, RouteComponentProps } from 'react-router-dom';
import BackgroundImage from 'giftcards/assets/background/sf_pretty.jpeg';

interface SignUpPageParams {
  product?: string;
}

export const SignupPage: React.FC<RouteComponentProps<SignUpPageParams>> = props => {
  const classes = useStyles();

  useEffect(() => {
    // TODO account for calendly load error
    attachCalendlyScript();
    {
      props.match.params.product === 'menu'
        ? (document.title = 'Paneau | Digital Menus')
        : (document.title = 'Paneau | Giftcards');
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.blurredBackground} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <div className={classes.header}>
              <img src={Logo} alt='Paneau' className={classes.logo} />
              <Typography variant='h5' component='h1' className={classes.title}>
                {props.match.params.product === 'menu' ? 'Digital Menus' : 'Giftcards'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper className={classes.form}>
              <SignUpFormContextProvider>
                <Typography className={classes.subtitle} component='h2'>
                  Create an Accounot
                </Typography>
                {props.match.params.product === 'menu' ? (
                  <SignUpForm
                    onAccountCreated={() => {
                      if (typeof window !== 'undefined') {
                        window.location.href = '/mymenumaker';
                      }
                    }}
                  />
                ) : (
                  <SignUpForm />
                )}
              </SignUpFormContextProvider>
              <Typography variant='body1'>
                Already have an account? <Link to='/login'>Log in</Link>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Paper className={classes.calendly}>
              <Typography className={classes.subtitle} component='h2'>
                Want to see a demo?
              </Typography>
              <div
                className={`${classes.calendlyWidget} calendly-inline-widget`}
                data-url='https://calendly.com/paneauonboarding/paneau-onboarding?primary_color=e088ff'
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

function attachCalendlyScript() {
  return new Promise(function(resolve, reject) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.addEventListener('load', resolve);
    script.addEventListener('error', reject);
    document.body.appendChild(script);
  });
}

interface SignUpFormProps {
  onAccountCreated?: (account: CreateAccountResponseBody) => void;
}
const defaultOnAccountCreated = (account: CreateAccountResponseBody) => {
  if (typeof window !== 'undefined') {
    window.location.href = '/';
  }
};

export const SignUpForm: React.FC<SignUpFormProps> = ({ onAccountCreated = defaultOnAccountCreated }) => {
  const { activeForm } = useContext(SignUpFormContext);
  return (
    <SwitchTransition>
      <FadeTransition key={activeForm}>
        {activeForm === 'business' || activeForm === 'demo' ? (
          <BusinessForm key='business' type={activeForm} onAccountCreated={onAccountCreated} />
        ) : activeForm === 'demosuccess' ? (
          <SuccessPage>
            <h3>
              Thank you for your interest!
              <br />
              Someone from our team will reach out shortly.
            </h3>
          </SuccessPage>
        ) : (
          <></>
        )}
      </FadeTransition>
    </SwitchTransition>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      paddingBottom: '1rem'
    },
    blurredBackground: {
      filter: 'blur(4px)',
      backgroundColor: '#fff',
      background: `url(${BackgroundImage}) no-repeat center center fixed`,
      backgroundSize: 'cover',
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: -1
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '3rem 0.5rem'
    },
    logo: {
      width: '200px'
    },
    title: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      color: '#4d5055'
    },
    subtitle: {
      fontSize: '26px',
      fontWeight: 600,
      color: '#4d5055',
      fontFamily: '"Proxima Nova", sans-serif',
      paddingBottom: '1rem',
      textAlign: 'center'
    },
    header: {
      padding: '3rem 0.5rem 1rem',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '2rem 0.5rem 1rem'
      }
    },
    calendly: {
      padding: '3rem 0.5rem',
      textAlign: 'center'
    },
    calendlyWidget: {
      'maxWidth': '100%',
      'height': '825px',
      'borderTop': '1px solid #ececec',
      '& > *': {
        fontFamily: `${theme.typography.fontFamily}!important`,
        color: '#333!important'
      },
      '& > h1': {
        color: '#333!important'
      }
    }
  })
);
