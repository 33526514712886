import useSafeFetch from 'shared/hooks/useSafeFetch';
import React, { useState, useRef, useEffect } from 'react';

interface StripeContextType {
  url: string;
  setup: boolean;
  refreshStripeData: () => void;
  loading: boolean;
  error: boolean;
}

export const StripeContext = React.createContext<StripeContextType>({
  url: '',
  setup: false,
  refreshStripeData: () => null,
  error: false,
  loading: false
});

interface State {
  url: string;
  setup: boolean;
  error: boolean;
}

export const STRIPE_ERROR_TEXT =
  'There was a problem checking the status of your Stripe account. If this problem persists, please contact our team for support.';

const StripeContextProvider: React.FC = props => {
  const [state, setState] = useState<State>({
    url: '',
    setup: false,
    error: false
  });
  const { get } = useSafeFetch();

  const refreshStripeData = useRef(async () => {
    try {
      const response = await get(`${process.env.REACT_APP_PORTAL}/offerings/payments`);
      const { issetup, stripe_oath_url } = await response.json();
      setState(s => ({
        ...s,
        setup: issetup,
        url: stripe_oath_url
      }));
    } catch (e) {
      console.error(e);
      if (!e.cancelled && !e.unauthenticated) {
        setState(s => ({ ...s, error: true }));
      }
    }

    try {
      const response = await get(`${process.env.REACT_APP_PORTAL}/offerings`);
      const offerings = await response.json();
      setState(s => ({ ...s, offerings }));
    } catch (e) {
      console.error(e);
      if (!e.cancelled && !e.unauthenticated) {
        setState(s => ({ ...s, error: true }));
      }
    }
  });

  useEffect(() => {
    refreshStripeData.current();
  }, []);

  return (
    <StripeContext.Provider
      value={{
        url: state.url,
        setup: state.setup,
        refreshStripeData: refreshStripeData.current,
        error: state.error,
        loading: !state.setup && !state.url && !state.error
      }}
    >
      {props.children}
    </StripeContext.Provider>
  );
};

export default StripeContextProvider;
