import React from 'react';
import Success from 'giftcards/assets/icons/success.svg';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const SuccessPage: React.FC<{ showIcon?: boolean }> = props => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      {props.children}
      {props.showIcon !== false && <img src={Success} alt='Paneau' className={classes.logo} />}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: '2rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '400px',
      alignItems: 'center',
      textAlign: 'center',
      paddingBottom: '2rem'
    },
    logo: {
      width: '150px',
      height: '150px'
    }
  })
);
