import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';

export interface Image {
  file: string | ArrayBuffer | null | undefined;
  name: string;
}

interface ImageUploadProps {
  onChange: (image: Image) => void;
}

/**
 * For now, this only supports a single image.
 */
const ImageUpload: React.FC<ImageUploadProps> = props => {
  const classes = useStyles();

  const onChange = async (event: any) => {
    if (event.target.files.length) {
      const image = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e =>
        props.onChange({
          file: e.target?.result,
          name: image.name
        });
      reader.readAsDataURL(image);
    }
  };

  return <input className={classes.root} type='file' accept='image/*' id='image-upload' onChange={onChange} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      'paddingTop': `${theme.spacing(1)}px`,
      'label + &': {
        marginTop: theme.spacing(2)
      }
    }
  })
);

export default ImageUpload;
