import useSafeFetch from 'shared/hooks/useSafeFetch';
import { useState, useContext } from 'react';
import { Typography, TextField, InputAdornment, Button, makeStyles, Theme } from '@material-ui/core';
import GiftCard from 'giftcards/common/types/GiftCard';
import React from 'react';
import ConfirmTransactionDialog from './ConfirmTransationDialog';
import { SnackbarContext } from 'giftcards/core/context/SnackbarContext';
import LoadingButton from 'shared/components/LoadingButton';
import { useHistory } from 'react-router-dom';

interface ChargeCardProps {
  card: GiftCard;
}

interface ConfirmTransactionState {
  show: boolean;
  remainingPurchase: string;
  charged: string;
}

// TODO refresh card on failure
const ChargeCard: React.FC<ChargeCardProps> = props => {
  const { post } = useSafeFetch();
  const classes = useStyles();
  const [amount, setAmount] = useState('');
  const [isAmountError, setIsAmountError] = useState(false);
  const [description, setDescription] = useState('');
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const { showSnackbarAlert } = useContext(SnackbarContext);
  const [confirmTransaction, setConfirmTransaction] = useState<ConfirmTransactionState>({
    show: false,
    remainingPurchase: '',
    charged: ''
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const chargeCard = async () => {
    let error = false;
    if (!amount) {
      error = true;
      setIsAmountError(true);
    }
    if (!description) {
      error = true;
      setIsDescriptionError(true);
    }
    if (!error) {
      if (verifyPayment(Number.parseFloat(amount))) {
        requestCharge(Number.parseFloat(amount), description);
      }
    }
  };

  const verifyPayment = (transactionAmount: number) => {
    const newBalance = props.card.remaining_balance - transactionAmount;
    if (newBalance < 0) {
      const remaining = transactionAmount - props.card.remaining_balance;
      setConfirmTransaction({
        show: true,
        remainingPurchase: remaining.toString(),
        charged: props.card.remaining_balance.toString()
      });
      return false;
    }
    return true;
  };

  const onConfirmTransaction = () => {
    requestCharge(props.card.remaining_balance, description);
  };

  const onCancelConfirmTransaction = () => {
    setConfirmTransaction({
      show: false,
      remainingPurchase: '',
      charged: ''
    });
  };

  const onDone = (success: boolean) => {
    if (success) {
      showSnackbarAlert('Charge Successful', 'success');
    }
    history.push('/');
    window.scrollTo(0, 0);
  };

  const requestCharge = async (amount: number, description: string) => {
    const payload = {
      amount: amount,
      description: description
    };
    try {
      setLoading(true);
      const response = await post(`${process.env.REACT_APP_PORTAL}/card/${props.card.uuid}/charge`, {
        body: JSON.stringify(payload)
      });
      setLoading(false);
      if (!response.ok) {
        showSnackbarAlert('Something went wrong', 'error');
      } else {
        onDone(true);
      }
    } catch (e) {
      console.error(e);
      if (!e.cancelled) {
        setLoading(false);
        showSnackbarAlert('Something went wrong', 'error');
      }
    }
  };

  const onDescriptionInput = (event: any) => {
    const description = event.target.value;
    setIsDescriptionError(!description);
    setDescription(description);
  };

  const onAmountInput = (event: any) => {
    const amount = event.target.value;
    const isValid = Boolean(amount) && !isNaN(amount);
    setIsAmountError(!isValid);
    setAmount(isValid ? amount : undefined);
  };

  return (
    <>
      <Typography variant='subtitle1' color='textSecondary' gutterBottom>
        Transaction
      </Typography>
      <div className={classes.content}>
        <Typography variant='body1'>
          <b>Name:</b> {props.card.purchaser_details.recipient_name || props.card.purchaser_details.name}
          <br />
          <b>Email:</b> {props.card.purchaser_details.recipient_email || props.card.purchaser_details.email}
          <br />
          <b>Code:</b> {props.card.short_code}
        </Typography>
        <p></p>
        <TextField
          required
          className={classes.chargeField}
          error={isAmountError}
          id='outlined-basic'
          label='Amount'
          variant='outlined'
          color='secondary'
          onInput={onAmountInput}
          InputProps={{
            startAdornment: <InputAdornment position='start'>{props.card.currency_symbol}</InputAdornment>
          }}
        />
        <TextField
          required
          error={isDescriptionError}
          className={classes.chargeField}
          id='outlined-basic'
          label='Description'
          variant='outlined'
          color='secondary'
          onInput={onDescriptionInput}
          InputLabelProps={{
            shrink: true
          }}
          multiline
        />
      </div>
      <div className={classes.actionBar}>
        <Button onClick={() => onDone(false)}>Cancel</Button>
        <LoadingButton loading={loading} color='secondary' variant='contained' onClick={chargeCard}>
          Charge
        </LoadingButton>
      </div>
      <ConfirmTransactionDialog
        open={confirmTransaction.show}
        transactionRemainder={confirmTransaction.remainingPurchase}
        transactionCharged={confirmTransaction.charged}
        onCancel={onCancelConfirmTransaction}
        onConfirm={onConfirmTransaction}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  shortCode: {
    marginBottom: 12
  },
  content: {
    'width': '18rem',
    'maxWidth': '100%',
    '& > p': {
      lineHeight: '1.75rem',
      marginBottom: '24px',
      marginTop: '16px'
    }
  },
  chargeField: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },
  actionBar: {
    'display': 'flex',
    'flexDirection': 'row',
    'minWidth': 200,
    'justifyContent': 'center',
    'paddingTop': theme.spacing(3),
    '& > button': {
      marginLeft: theme.spacing(1)
    }
  }
}));

export default ChargeCard;
