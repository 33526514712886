import React, { useState, useRef, useEffect } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { isLocal } from 'shared/utils/DevUtils';

interface CreateAccountRequestBody {
  username: string;
  password: string;
  email: string;
  phoneNumber: string;
  businessName: string;
  couponCode?: string;
  activationCode?: string;
  short_code?: string;
}

export interface CreateAccountResponseBody {
  id: string;
  username: string;
  business_name: string;
}

interface RecruitRequestBody {
  username: string;
  email: string;
  phone: string;
}

interface DemoRequestBody {
  business_name: string;
  name: string;
  number: string;
  email: string;
}

interface SignUpForm {
  activationCode: string;
  businessname: string;
  name: string;
  email: string;
  phone: string;
  password: string;
  short_code: string;
}

const emptySignUpForm = {
  activationCode: '',
  businessname: '',
  name: '',
  email: '',
  phone: '',
  password: '',
  short_code: ''
};

export const SignUpFormContext = React.createContext<{
  value: SignUpForm;
  updateValue: (field: string, newVal: string) => void;
  submitRecruit: (d: Partial<SignUpForm>) => Promise<Response>;
  createAccount: (d: Partial<SignUpForm>) => Promise<Response>;
  requestDemo: (d: Partial<SignUpForm>) => void;
  activeForm: FormType;
  showDemoForm: () => void;
  showSignUpForm: () => void;
}>({
  value: emptySignUpForm,
  updateValue: () => null,
  submitRecruit: () => null as any,
  createAccount: () => null as any,
  requestDemo: () => null,
  activeForm: 'business',
  showDemoForm: () => null,
  showSignUpForm: () => null
});

export const makeCreateAccountRequest = async (body: CreateAccountRequestBody) => {
  const domain = isLocal() ? process.env.REACT_APP_PORTAL : '';
  return await fetch(`${domain}/api/signup`, {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  });
};

type FormType = 'business' | 'signup' | 'demo' | 'demosuccess';

const SignUpFormContextProvider: React.FC = props => {
  const value = useRef<SignUpForm>(emptySignUpForm);
  const [activeForm, setActiveForm] = useState<FormType>('business');
  const successTimeout = useRef<number>();

  useEffect(() => {
    return clearTimeout(successTimeout.current);
  }, []);

  const submitRecruit = async (recruit: Partial<SignUpForm>): Promise<Response> => {
    setActiveForm('signup');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const body: RecruitRequestBody = {
      username: recruit.businessname!,
      email: recruit.email!,
      phone: recruit.phone!
    };
    // TODO
    return new Promise(resolve => resolve());
  };

  const createAccount = async (loginInfo: Partial<SignUpForm>): Promise<Response> => {
    const body: CreateAccountRequestBody = {
      username: loginInfo.email!,
      password: loginInfo.password!,
      email: value.current.email!,
      businessName: value.current.businessname!,
      phoneNumber: parsePhoneNumberFromString(value.current.phone, 'US')?.formatNational()!,
      activationCode: value.current.activationCode,
      short_code: new URLSearchParams(window.location.search).get('short_code') || ''
    };
    return makeCreateAccountRequest(body);
  };

  const showDemoSuccess = () => {
    setActiveForm('demosuccess');
    successTimeout.current = setTimeout(() => setActiveForm('business'), 5000) as any;
  };

  const requestDemo = async (data: Partial<SignUpForm>) => {
    const body: DemoRequestBody = {
      business_name: data.businessname!,
      name: data.name!,
      number: data.phone!,
      email: data.email!
    };
    const requestInit = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };
    if (process.env.GATSBY_REQUEST_DEMO_URL) {
      await fetch(process.env.GATSBY_REQUEST_DEMO_URL, requestInit);
      showDemoSuccess();
      return;
    }
    if (process.env.GATSBY_ACTIVE_ENV === 'development') {
      showDemoSuccess();
      logRequest('REQUEST DEMO', requestInit);
      return;
    }
    throw new Error('Missing env.');
  };

  const showDemoForm = () => {
    clearTimeout(successTimeout.current);
    setActiveForm('demo');
  };
  const showSignUpForm = () => {
    clearTimeout(successTimeout.current);
    setActiveForm('business');
  };

  const updateValue = (field: string, newVal: string) => (value.current = { ...value.current, [field]: newVal });

  return (
    <SignUpFormContext.Provider
      value={{
        value: value.current,
        updateValue,
        submitRecruit,
        createAccount,
        requestDemo,
        activeForm,
        showDemoForm,
        showSignUpForm
      }}
    >
      {props.children}
    </SignUpFormContext.Provider>
  );
};

const logRequest = async (action: string, requestInit: RequestInit) => {
  console.log(action, requestInit);
  return;
};

export default SignUpFormContextProvider;
