import React, { useEffect, useRef } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Paper, CardMedia, Grid, Badge, Button } from '@material-ui/core';
import FoodItem from './FoodItem';
import { string } from 'yup';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '25px',
      backgroundColor: "#FFF"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    itemBlock: {
        padding: '15px',
        margin: '7px',
    },
    media: {
      height: 140,
    },
  }),
);

interface ExpandableSectionProps {
    label: string,
    description?: string,
    image?:string,
    items: {
      label: string,
      description? : string,
      price? : number
      image? : string
    }[],
}


// export default function ExpandableSection() {
const ExpandableSection: React.FC<ExpandableSectionProps> = props => {
  const classes = useStyles();
  const [showDetails, setShowDetails] = React.useState<boolean>( props.items.length > 2 ? false : true );
  const [showScrollUp, setShowScrollUp] = React.useState<boolean>(false);
  const [divOffestTop, setDivOffsetTop] = React.useState<number>(0);

  const setDivHeight = (ref: any) => {
    if (ref !=null) {
      setShowScrollUp( ref.clientHeight > window.innerHeight );
      setDivOffsetTop( ref.offsetTop );
    }
  }

  return (
    <Grid container 
      style={{marginLeft:"-7px"}} // hacky way to center the div, it's off by 5px for some reason
    >
      <Grid item xs={12}>
      <Typography variant={"body1"}>{props.description}</Typography>
      </Grid>
      <Grid container xs={12} ref={ref => setDivHeight(ref)}>

          {props.items.map((item, index) => (
              <Grid item xs={12} md={6}>
              <FoodItem 
                  bgImage={item.image}
                  header={item.label}
                  body1={item.description as string} //banana: this could be an issue
                  // body2={"Vegetarian Spring Roll: Tofu + Mushrooms"}
                  price={item.price}
                  />
              </Grid>
          ))}
      </Grid>

{/* This UI interaction needs a bit more work */}
        {/* {showDetails?
        <>
            <Grid item xs={12}>
            <Typography variant={"body1"}>{props.description}</Typography>
            </Grid>
            <Grid container xs={12} ref={ref => setDivHeight(ref)}>

                {props.items.map((item, index) => (
                    <Grid item xs={12} md={6}>
                    <FoodItem 
                        bgImage={item.image}
                        header={item.label}
                        body1={item.description as string} //banana: this could be an issue
                        // body2={"Vegetarian Spring Roll: Tofu + Mushrooms"}
                        price={item.price}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
        :
        <>
            <Grid item xs={6} md={6}>
                {props.items.map((item) => (
                    <Paper onClick={ () => setShowDetails(!showDetails) } elevation={1} className={classes.itemBlock}>
                        <p>{item.label}</p>
                    </Paper>
                ))}
            </Grid>
            <Grid item xs={6} md={6}>
                <CardMedia
                className={classes.media}
                image={props.image}
                title="Saiwalks"
                />
            </Grid>
        </>
        } */}

        { showScrollUp ? 
          <Button variant="outlined" style={{textTransform: "lowercase"}} onClick={()=> scroll.scrollTo(divOffestTop)}>scroll up</Button>
        : 
          ''
        }


    </Grid>
  );
};

export default ExpandableSection;