import React, { useEffect, useContext } from 'react';
import { CssBaseline, CircularProgress, Container } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import AppBar from '../AppBar';
import AppTheme from '../AppTheme';
import Setup from 'giftcards/setup';
import Dashboard from 'giftcards/dashboard';
import MenuManager from 'giftcards/menus';
import { SwitchTransition } from 'react-transition-group';
import FadeTransition from 'shared/components/FadeTransition';
import EditOfferingPage from 'giftcards/editofferingpage';
import AuthMenuMaker from 'giftcards/menumakerpage/AuthMenuMaker';
import OpenMenuMaker from 'giftcards/menumakerpage/OpenMenuMaker';
import Redeem from 'giftcards/redeem';
import GiftCardOfferingsContextProvider, { OfferingsContext } from 'giftcards/core/context/OfferingsContext';
import MenuContextProvider, { MenusContext } from 'giftcards/core/context/MenusContext';
import UserContextProvider, { UserContext, UserType } from 'giftcards/core/context/UserContext';
import { Alert } from '@material-ui/lab';
import SnackbarContextProvider from 'giftcards/core/context/SnackbarContext';
import AppSnackbar from '../AppSnackbar';
import { Auth } from 'giftcards/auth';
import NotFound from '../404';
import StripeContextProvider, { StripeContext } from 'giftcards/core/context/StripeContext';
import SharePage from 'giftcards/share';
import Designer from '../../../designer';
import { Menu } from '../../../Menu';
import MenuPage from 'giftcards/Menu/MenuPage';

const App: React.FC = () => {
  return (
    <Router>
      <AppTheme>
        <CssBaseline />
        <UserContextProvider>
          <SnackbarContextProvider>
            <Switch>
              {/* /menu is an unprotected path, doesnt need the stripe/snackbar context provider */}
              <Route path='/menu/:uri' component={MenuPage} />
              <Route path='/menumaker/:uri?' component={OpenMenuMaker} />
              <Route exact path='/login' component={Auth} />
              <Route path='/signup/:product?' component={Auth} />
              <StripeContextProvider>
                <Route exact path='*' component={AuthenticatedApp} />
              </StripeContextProvider>
            </Switch>
            <AppSnackbar />
          </SnackbarContextProvider>
        </UserContextProvider>
      </AppTheme>
    </Router>
  );
};

const AuthenticatedApp: React.FC = () => {
  const classes = useStyles();
  return (
    <AppTheme>
      <div className={classes.root}>
        <GiftCardOfferingsContextProvider>
          <MenuContextProvider>
            <AppBar />
            <Switch>
              <Route path='/mymenumaker' component={AuthMenuMaker} />
              <Route exact path='*' component={AppContent} />
            </Switch>
          </MenuContextProvider>
        </GiftCardOfferingsContextProvider>
      </div>
    </AppTheme>
  );
};

declare global {
  interface Window {
    FS: any;
  }
}

const identifyFullstory = (user: UserType | undefined) => {
  if (!user) return;
  (window as Window).FS.identify(user.uid, {
    displayName: user.businessName,
    email: user.email,
    username: user.username
  });
};

const AppContent = () => {
  const classes = useStyles();
  const history = useHistory();
  const offeringsContext = useContext(OfferingsContext);
  const userContext = useContext(UserContext);
  const stripeContext = useContext(StripeContext);

  const { user } = userContext;
  useEffect(() => {
    identifyFullstory(user);
  }, [user]);

  if (userContext.loading || offeringsContext.loading || stripeContext.loading) {
    return (
      <>
        {stripeContext.error ? <StripeError /> : null}
        <div className={classes.fullPage}>
          <CircularProgress size='6rem' />
        </div>
      </>
    );
  }

  const setupComplete = offeringsContext.setup && stripeContext.setup;

  return (
    <Container>
      {stripeContext.error ? <StripeError /> : null}
      <SwitchTransition>
        <FadeTransition key={`${history.location.pathname}`}>
          {setupComplete ? (
            <Switch>
              <Route path='/editcard' component={EditOfferingPage} />
              <Route path='/chargecard' component={Redeem} />
              <Route path='/share' component={SharePage} />
              <Route path='/designer' component={Designer} />
              <Route path='/' component={Dashboard} exact />
              <Route path='*' component={NotFound} />
            </Switch>
          ) : (
            <Setup key='setup' />
          )}
        </FadeTransition>
      </SwitchTransition>
    </Container>
  );
};

const StripeError = () => {
  const classes = useStyles();
  return (
    <Alert className={classes.alert} elevation={0} variant='filled' severity='error'>
      There was a problem checking the status of your Stripe account. If this problem persists, please contact our team
      for support at team@paneau.io.
    </Alert>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      'minWidth': '320px',
      'paddingTop': '56px',
      '@media (min-width: 600px)': {
        paddingTop: '64px !important'
      },
      '@media (min-width: 0px) and (orientation: landscape)': {
        paddingTop: '48px'
      },
      'backgroundColor': '#FFF'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    toolbar: theme.mixins.toolbar,
    fullPage: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    alert: {
      marginTop: theme.spacing(2)
    }
  })
);

export default App;
