import { Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import SwatchesPopover from './SwatchesPopover';

interface ColorPickerProps {
  value: string;
  onChange: (hex: string) => void;
  onClose: () => void;
}

const ColorPicker: React.FC<ColorPickerProps> = props => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(undefined);

  const onChange = ({ hex }: { hex: string }) => {
    props.onChange(hex);
  };

  const close = () => {
    setOpen(false);
    props.onClose();
  };

  const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <>
      <Button
        onClick={onButtonClick}
        variant='contained'
        className={classes.root}
        style={{ backgroundColor: props.value }}
      >
        {' '}
      </Button>
      <SwatchesPopover
        value={props.value}
        open={Boolean(open)}
        anchorEl={anchorEl}
        onChange={onChange}
        onClose={close}
      />
    </>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      'width': '3rem',
      'height': '3rem',
      'minWidth': '3rem',
      'label + &': {
        marginTop: theme.spacing(1)
      }
    }
  })
);

export default ColorPicker;
