import { Container, Grid, withWidth, isWidthUp, WithWidthProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import SpendingCard from './components/SpendingCard';
import PreviewCard from './components/PreviewCard';
import CardCountCard from './components/CardCountCard';
import TotalValueCard from './components/TotalValueCard';
import { OfferingLinkCard } from './components/LinkCard';
import { OfferingsContext } from 'giftcards/core/context/OfferingsContext';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import { Alert } from '@material-ui/lab';
import CardsTable from './components/CardsTable';
import ActionsButton from './components/ChargeButton';
import TransactionsTable from './components/TransactionsTable';
import { UserContext } from 'giftcards/core/context/UserContext';
import { StripeContext } from 'giftcards/core/context/StripeContext';

interface OfferingMetrics {
  active_card_count: number;
  dollar_remaining_total: number;
  dollar_spend_total: number;
  purchased_card_count: number;
  transactions: any[];
}

const Dashboard: React.FC<WithWidthProps> = props => {
  const classes = useStyles();
  const { offerings } = useContext(OfferingsContext);
  const stripeContext = useContext(StripeContext);
  const { user } = useContext(UserContext);
  const { get } = useSafeFetch();
  const [metrics, setMetrics] = useState<OfferingMetrics>();
  const [error, setError] = useState<string>();

  const offeringUuid = offerings?.[0].uuid;

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        const response = await get(`${process.env.REACT_APP_PORTAL}/offerings/${offerings?.[0].uuid}/metrics`);
        setMetrics(await response.json());
      } catch (e) {
        console.error(e);
        if (!e.cancelled) {
          setError('An error occured while populating your dashboard. Please try again.');
        }
      }
    };

    fetchMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offeringUuid]);

  const dollarTotal = metrics?.dollar_spend_total;
  const dollarsRedeemed = metrics && metrics.dollar_spend_total - metrics.dollar_remaining_total;
  const spacing = isWidthUp('sm', props.width!!) ? (isWidthUp('md', props.width!!) ? 3 : 2) : 1;
  const accountPending = user && !user.authorized;

  return (
    <Container className={classes.root}>
      {error && (
        <Alert severity='error' className={classes.alert}>
          {error}
        </Alert>
      )}
      {accountPending && (
        <Alert severity='info' className={classes.alert}>
          Thank you for creating an account! Your account is currently pending verification by the Paneau team. Your
          gift card page will be ready for use once this process is complete.
        </Alert>
      )}
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <Grid container spacing={spacing} className={classes.top}>
            <Grid item className={classes.hiddenItem} />
            <Grid item className={classes.linkContainer}>
              <OfferingLinkCard offeringUri={offerings?.[0]?.uri} />
            </Grid>
            <Grid item className={classes.chargeButtonContainer}>
              <ActionsButton />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <PreviewCard {...offerings?.[0]} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              <Grid container spacing={spacing}>
                <Grid item xs={12} sm={6}>
                  <CardCountCard active={metrics?.active_card_count} total={metrics?.purchased_card_count} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TotalValueCard
                    total={dollarTotal}
                    symbol={offerings?.[0]?.currency_symbol || '$'}
                    stripeUrl={stripeContext.url}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SpendingCard
                total={dollarTotal}
                symbol={offerings?.[0]?.currency_symbol || '$'}
                spent={dollarsRedeemed}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TransactionsTable transactions={metrics?.transactions} />
      <CardsTable />
    </Container>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: '2rem 0',
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('md')]: {
        padding: '1rem 0'
      }
    },
    top: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column-reverse'
      }
    },
    hiddenItem: {
      'width': '192px',
      'flexShrink': 0,
      'flexGrow': 0,
      [theme.breakpoints.down('md')]: {
        width: 0,
        padding: '0 !important'
      },
      '&.MuiGrid-item': {
        padding: 0
      }
    },
    linkContainer: {
      flexGrow: 1,
      flexShrink: 1,
      maxWidth: '100%',
      width: '100%',
      minWidth: 0
    },
    chargeButtonContainer: {
      flexShrink: 0,
      flexGrow: 0,
      width: '200px'
    },
    alert: {
      marginBottom: theme.spacing(2)
    }
  })
);

export default withWidth()(Dashboard);
