import React, { useState } from 'react';
import { Color } from '@material-ui/lab/Alert';

interface SnackbarState {
  message: string;
  severity?: Color;
}

interface SnackbarContextType extends SnackbarState {
  message: string;
  severity?: Color;
  showSnackbarAlert: (message: string, severity: Color) => void;
}

export const SnackbarContext = React.createContext<SnackbarContextType>({
  message: '',
  severity: undefined,
  showSnackbarAlert: (m: string, s: Color) => null
});

const SnackbarContextProvider: React.FC = props => {
  const [state, setState] = useState<SnackbarState>({ message: '', severity: undefined });
  const showSnackbarAlert = (message: string, severity: Color) => {
    setState({ message, severity });
  };

  return (
    <SnackbarContext.Provider value={{ message: state.message, severity: state.severity, showSnackbarAlert }}>
      {props.children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarContextProvider;
