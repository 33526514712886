import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Paper, Badge, Chip, Snackbar, Slide } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import './clickableChip.css';
import { CartContext } from '.';
// import { MyCartContextProvider, MyCartContext } from './CartContext';

const useStyles = makeStyles({
  root: {
    maxWidth: 345
  },
  media: {
    height: 140
  },
  itemBlock: {
    padding: '15px',
    margin: '7px'
  }
});

export interface FoodItemProps {
    bgImage: any,
    header: string,
    body1: string,
    body2?: string,
    price?: number,
}

export default function FoodItem(prop: FoodItemProps) {
  const classes = useStyles();
  const [showConfirmation, setShowConfirmation] = React.useState<boolean>(false);

  // Context from Menu
  const { cart: myCart, changeCart: setMyCart } = useContext(CartContext);

  useEffect(() => {
    if (showConfirmation) {
      setTimeout(() => {
        setShowConfirmation(false);
      }, 2000);
    }
  }, [showConfirmation]);

  return (
    <Paper elevation={3} className={classes.itemBlock} style={{ width: '100%' }}>
      {prop.bgImage ? <CardMedia className={classes.media} image={prop.bgImage} title='Saiwalks' /> : <></>}
      <h2>{prop.header}</h2>
      <p>{prop.body1}</p>
      {/* <p>{prop.body2}</p> */}
      <Chip
        variant='outlined'
        icon={<AddIcon />}
        label={`$${prop.price}`}
        onClick={() => {
          setShowConfirmation(true);
          const newCart = [
            ...myCart,
            {
              label: prop.header,
              description: 'test',
              price: prop.price,
              image: 'test'
            }
          ];
          setMyCart(newCart);
        }}
      />

      <Snackbar open={showConfirmation} onClose={() => setShowConfirmation(false)} message={`+ Added ${prop.header}`} />
    </Paper>
  );
}
