import React, { useEffect } from 'react';
import EditMenu from 'giftcards/common/components/EditMenu';
import { makeStyles, createStyles, Theme, Container, Paper, Grid } from '@material-ui/core';
import BackButton from 'shared/components/BackButton';
import { useHistory } from 'react-router-dom';
import { LinkCardBlockable } from '../dashboard/components/LinkCard';
import { UserContext } from 'giftcards/core/context/UserContext';

interface MenuMakerProps {
  save: (yml: string) => void;
  menuYml?: string;
  menuUri?: string;
}

const MenuMaker: React.FC<MenuMakerProps> = props => {
  const classes = useStyles();
  const history = useHistory();

  const back = () => {
    history.push('/');
    window.scrollTo(0, 0);
  };

  return (
    <Container className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {/* This container pushes the menu link together with the hidden elements */}
          <Grid container spacing={2} className={classes.top}>
            <Grid item className={classes.hiddenItem} />
            <Grid item className={classes.linkContainer}>
              <MenuLinkCard hideShareButton menuUri={props.menuUri} />
            </Grid>
            <Grid item className={classes.hiddenItem} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={1} className={classes.paper}>
            <EditMenu hideCancel={true} save={props.save} menuYml={props.menuYml} title='Menu Maker' />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2rem 0',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem 0'
      }
    },
    top: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column-reverse'
      }
    },
    paper: {
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    },
    linkContainer: {
      flexGrow: 1,
      flexShrink: 1,
      maxWidth: '100%',
      width: '100%',
      minWidth: 0
    },
    hiddenItem: {
      'width': '192px',
      'flexShrink': 0,
      'flexGrow': 0,
      [theme.breakpoints.down('md')]: {
        width: 0,
        padding: '0 !important'
      },
      '&.MuiGrid-item': {
        padding: 0
      }
    }
  })
);

interface MenuLinkCardProps {
  menuUri?: string;
  className?: string;
  hideTitle?: boolean;
  hideShareButton?: boolean;
}

const MenuLinkCard: React.FC<MenuLinkCardProps> = props => {
  const shareUrl = `${process.env.REACT_APP_CARDS}/menu/${props.menuUri}`;
  const shouldBlock = !Boolean(props.menuUri);
  return (
    <LinkCardBlockable
      {...props}
      label={'Menu Link'}
      url={shareUrl}
      shouldBlock={shouldBlock}
      blockMessage={'Save your menu to get a shareable menu url'}
    />
  );
};

export default MenuMaker;
