import { Paper, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React from 'react';
import SpendingChart from './SpendingChart';

interface SpendingCardProps {
  total?: number;
  spent?: number;
  symbol: string;
}

const SpendingCard: React.FC<SpendingCardProps> = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHead}>
        <Typography variant='h5' component='h2'>
          Gift Card Metrics
        </Typography>
        <Typography variant='h5' component='h2'>
          {props.total !== undefined && (
            <span>
              Total:{' '}
              <b>
                {props.symbol}
                {props.total}
              </b>
            </span>
          )}
        </Typography>
      </div>
      <div className={classes.content}>
        {props.spent === undefined ? (
          <div className={classes.loaderContainer}>
            <CircularProgress size='4rem' />
          </div>
        ) : (
          <div className={classes.chartContainer}>
            <SpendingChart {...props} />
          </div>
        )}
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      minHeight: '17.465rem'
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    paperHead: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    chartContainer: {
      flexShrink: 1,
      flexGrow: 1
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: '13rem',
      alignItems: 'center'
    }
  })
);

export default SpendingCard;
