import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import { Grid, Button, Typography } from '@material-ui/core';
import SignUpFormContextProvider, {
  SignUpFormContext,
  CreateAccountResponseBody
} from '../auth/signup/SignUpFormContext';
// import {SignUpFormContext} from '../auth/signup'

import { SignUpForm } from 'giftcards/auth/signup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      outline: 0
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      outline: 0,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: '7px'
    },
    subtitle: {
      fontSize: '26px',
      fontWeight: 600,
      color: '#4d5055',
      fontFamily: '"Proxima Nova", sans-serif',
      paddingBottom: '1rem',
      textAlign: 'center'
    }
  })
);

interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

interface SignUpModalProps {
  open: boolean;
  onClose: () => void;
  onAccountCreated: (account: CreateAccountResponseBody) => void;
  onSaveAnon: () => void;
}
const SignUpModal: React.FC<SignUpModalProps> = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={props.onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={props.open}>
        <div className={classes.paper}>
          {/* <Grid container> */}
          <Grid item xs={12} direction='column' justify='center' alignItems='center' style={{ textAlign: 'center' }}>
            <h1 id='save-modal-title'>Save your menu!</h1>
            <h2>Create an account to claim your menu</h2>
            <p id='save-modal-description'>Otherwise anyone can update your menu.</p>

            <SignUpFormContextProvider>
              <SignUpForm onAccountCreated={props.onAccountCreated} />
            </SignUpFormContextProvider>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <p>- or - </p>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button variant='outlined' style={{ textTransform: 'lowercase' }} onClick={() => props.onSaveAnon()}>
              Nah, just save
            </Button>
          </Grid>
          {/* </Grid> */}
        </div>
      </Fade>
    </Modal>
  );
};
export default SignUpModal;
