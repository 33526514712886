import React from 'react';
import EditOffering from 'giftcards/common/components/EditOffering';
import { makeStyles, createStyles, Theme, Container, Paper } from '@material-ui/core';
import BackButton from 'shared/components/BackButton';
import { useHistory } from 'react-router-dom';

const EditOfferingPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const back = () => {
    history.push('/');
    window.scrollTo(0, 0);
  };
  return (
    <Container className={classes.root}>
      <Paper elevation={1} className={classes.paper}>
        <BackButton back={back} />
        <EditOffering done={back} title='Edit Your Gift Card' />
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '2rem 0',
      [theme.breakpoints.down('sm')]: {
        padding: '1rem 0'
      }
    },
    paper: {
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    }
  })
);

export default EditOfferingPage;
