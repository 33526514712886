import React, { useRef, useContext } from 'react';
import { Container, Button, Typography, Paper } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import { OfferingLinkCard } from 'giftcards/dashboard/components/LinkCard';
import { useHistory } from 'react-router-dom';
import SocialMediaShareButtons from 'shared/components/SocialMediaShareButtons';
import { ShareButtonHTML } from '../setup/ShareButton';
import BackButton from 'shared/components/BackButton';
import { OfferingsContext } from 'giftcards/core/context/OfferingsContext';

const SharePage: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { offerings } = useContext(OfferingsContext);
  const textareaRef = useRef<HTMLTextAreaElement | null>();
  const done = () => {
    history.push('/');
    window.scrollTo(0, 0);
  };

  const urlBase = offerings?.[0]?.uri || '';
  const directShareUrl = `${urlBase}?utm_source=direct_link&utm_medium=social`;
  const shareButtonHtml = ShareButtonHTML(urlBase).replace(/(\r\n|\n|\r)/gm, '');
  const highlightOnClick = () => textareaRef.current?.select();

  return (
    <Container className={classes.root}>
      <Paper elevation={1} className={classes.paper}>
        <div className={classes.backButtonContainer}>
          <BackButton back={done} />
        </div>
        <Typography variant='h4' component='h1'>
          Promote Your Gift Card on Social Media and Your Website
        </Typography>
        <section className={classes.section}>
          <Typography variant='h5' component='h1' className={classes.subtitle}>
            1. Share It With Your Network
          </Typography>
          <Typography variant='body1' component='p' className={classes.p}>
            Share this page with customers & followers and encourage them to share it within their own social networks.
          </Typography>
          <div className={classes.shareButtons}>
            <SocialMediaShareButtons />
          </div>
          <Typography variant='body1' component='p' className={classes.p}>
            or share this link anywhere:
          </Typography>
          <OfferingLinkCard
            offeringUri={directShareUrl}
            className={classes.linkCard}
            hideTitle={true}
            hideShareButton={true}
          />
        </section>
        <section className={classes.section}>
          <Typography variant='h5' component='h1' className={classes.subtitle}>
            2. Add This Button to Your Site
          </Typography>
          <div className={classes.shareButton} dangerouslySetInnerHTML={{ __html: shareButtonHtml }}></div>
          <textarea
            className={classes.textarea}
            value={shareButtonHtml}
            ref={ref => (textareaRef.current = ref)}
            onClick={highlightOnClick}
          />
        </section>
        <div className={classes.buttonBar}>
          <Button onClick={done} variant='contained' color='secondary'>
            Done
          </Button>
        </div>
      </Paper>
    </Container>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: '2rem 0',
      textAlign: 'center'
    },
    paper: {
      width: '100%',
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    },
    backButtonContainer: {
      textAlign: 'left'
    },
    alert: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4)
    },
    buttonBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: '2rem'
    },
    linkContainer: {
      marginTop: theme.spacing(4)
    },
    link: {
      'textDecoration': 'none',
      '& > button': {
        width: '250px'
      }
    },
    shareButtons: {
      marginBottom: '2rem'
    },
    linkCard: {
      display: 'inline-block'
    },
    section: {
      margin: '3rem auto'
    },
    subtitle: {
      paddingTop: '1rem',
      paddingBottom: '1.5rem'
    },
    p: {
      paddingBottom: '1rem'
    },
    shareButton: {
      display: 'flex',
      justifyContent: 'center'
    },
    textarea: {
      width: '500px',
      maxWidth: '100%',
      height: '150px',
      marginTop: '1rem'
    }
  })
);

export default SharePage;
