import React, { useEffect } from 'react';
import { makeStyles, createStyles, Theme, Container, Paper, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MenuMaker from '.';
import { UserContext } from 'giftcards/core/context/UserContext';
import { MenusContext } from 'giftcards/core/context/MenusContext';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import { SnackbarContext } from 'giftcards/core/context/SnackbarContext';

const AuthMenuMaker: React.FC = () => {
  const history = useHistory();
  const { user, loading, refreshUser } = React.useContext(UserContext);
  const { menus, refreshMenus } = React.useContext(MenusContext);
  const { post, put } = useSafeFetch();
  const { showSnackbarAlert } = React.useContext(SnackbarContext);

  useEffect(() => {
    refreshMenus();
  }, [user]);

  useEffect(() => {
    refreshUser();
  }, []);

  const onSave = async (yml: string) => {
    if (menus == undefined) {
      return;
    }

    if (menus.length > 0) {
      // update
      const menu = menus[0];
      const payload = {
        config: yml
      };
      await put(`${process.env.REACT_APP_PORTAL}/menus/${menu.uri}`, {
        body: JSON.stringify(payload)
      });
    } else {
      // create
      const payload = {
        config: yml,
        business_id: user?.uid
      };
      const res = await post(`${process.env.REACT_APP_PORTAL}/menus`, {
        body: JSON.stringify(payload)
      });
      await res.json();
      refreshMenus();
    }
    showSnackbarAlert('Saved!', 'success');
  };

  const menu = menus && menus?.length > 0 ? menus[0] : undefined;

  return (
    <>
      <MenuMaker save={onSave} menuYml={menu?.config} menuUri={menu?.uri} />
    </>
  );
};

export default AuthMenuMaker;
