import React, { useContext, useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logo from '../assets/saiwalks.png';
import rollsImg from '../assets/menu/rolls.jpeg';
import rollsImg1 from '../assets/menu/imperialroll1.jpg';
import rollsImg2 from '../assets/menu/imperialroll2.jpg';
import rollsImg3 from '../assets/menu/springroll1.jpg';
import bm1 from '../assets/menu/banhmi1.jpg';
import bm2 from '../assets/menu/banhmi2.jpg';
import { Paper, CardMedia, Grid, Button } from '@material-ui/core';
import FoodItem from './FoodItem';
import { string } from 'yup';
import ExpandableSection from './ExpandableSection';
import yaml from 'js-yaml';
import { raw } from 'typesafe-joi/dist';
import { ItemSelectionList } from './ItemSelectionList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '25px',
      backgroundColor: '#FFF'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    itemBlock: {
      padding: '15px',
      margin: '7px'
    },
    media: {
      height: 140
    }
  })
);

interface MenuData {
  name: string;
  catchphrase?: string;
  hours?: string;
  website?: string;
  logo?: string;
  sections: {
    label: string;
    description?: string;
    image?: string;
    items: {
      label: string;
      description?: string;
      price?: number;
      image?: string;
    }[];
  }[];
}
export interface CartItem {
  label: string;
  description?: string;
  price?: number;
  image?: string;
}
let dummyCart: CartItem = {
  label: 'test',
  description: 'test',
  price: 0,
  image: 'test'
};
export const CartContext = React.createContext({
  cart: [dummyCart],
  changeCart: (args: any) => {}
});

interface CartState {
  cart: CartItem[];
  changeCart: (cart: any) => void;
}

export const MyCartContextProvider: React.FC = props => {
  const [cartState, setCartState] = React.useState<CartState>({
    cart: [],
    changeCart: (cart: any) => {
      setCartState(prevState => ({ ...prevState, cart: cart }));
    }
  });

  return <CartContext.Provider value={cartState}>{props.children}</CartContext.Provider>;
};
interface MenuProps {
  menuYml: string;
}

const ParseMenuYmlToMenu = (menuYml: string): MenuData => {
  // right now assumes valid yaml and valid menuYml
  const rawMenuData = yaml.safeLoad(menuYml);

  if (rawMenuData == undefined) {
    return {
      name: '',
      sections: []
    };
  }

  // TODO allow for null sections
  const sections: any = Object.keys(rawMenuData.sections).map(k => ({
    label: k,
    description: rawMenuData.sections[k] != undefined ? rawMenuData.sections[k].description : '',
    image: rawMenuData.sections[k] != undefined ? rawMenuData.sections[k].image : ''
  }));

  Object.keys(rawMenuData.sections).forEach((key, i) => {
    if (rawMenuData.sections[key] != undefined) {
      Object.keys(rawMenuData.sections[key]).forEach(subKey => {
        // description for section defined in sections ^
        if (subKey != 'description' && subKey != 'image') {
          const subSect = rawMenuData.sections[key][subKey];
          let item;

          // if item just being created and is just key
          if (subSect == undefined) {
            item = { label: subKey, description: '', price: '', image: '' };
          } else {
            item = {
              label: subKey,
              description: subSect.description,
              price: subSect.price,
              image: subSect.image
            };
          }

          // if item not
          if (sections[i].items == undefined) {
            sections[i].items = [item];
          } else {
            sections[i].items.push(item);
          }
        }
      });
    }
    // if section just being created and is just key
    else {
      sections[i].items = [];
    }
  });

  const menu: MenuData = {
    name: rawMenuData.title,
    catchphrase: rawMenuData.subtitle,
    hours: rawMenuData.hours,
    website: rawMenuData.website,
    logo: rawMenuData.logo,
    sections: sections
  };
  return menu;
};

export const Menu: React.FC<MenuProps> = props => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [showDetails, setShowDetails] = React.useState<boolean>(false);
  const [menuData, setMenuData] = React.useState<MenuData>();

  useEffect(() => {
    const menu = ParseMenuYmlToMenu(props.menuYml);
    setMenuData(menu);
  }, [props.menuYml]);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (menuData == undefined) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '25px' }}>
        <img src={menuData.logo} style={{ maxHeight: '100px' }} />
      </div>
      <MyCartContextProvider>
        <ItemSelectionList />
        <div style={{ marginBottom: '10px' }}>
          {menuData.sections.map((section, index) => (
            <ExpansionPanel expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index + 1}a-content`}
                id={`panel${index + 1}a-header`}
              >
                <Typography className={classes.heading}>{section.label}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <ExpandableSection
                  label={section.description as string}
                  description={''}
                  image={section.image}
                  items={section.items}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </div>
      </MyCartContextProvider>
    </div>
  );
};
