import React, { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import logo from '../assets/saiwalks.png';
import rollsImg from '../assets/menu/rolls.jpeg';
import rollsImg1 from '../assets/menu/imperialroll1.jpg';
import rollsImg2 from '../assets/menu/imperialroll2.jpg';
import rollsImg3 from '../assets/menu/springroll1.jpg';
import bm1 from '../assets/menu/banhmi1.jpg';
import bm2 from '../assets/menu/banhmi2.jpg';
import { Paper, CardMedia, Grid, Button } from '@material-ui/core';
import FoodItem from './FoodItem';
import { string } from 'yup';
import { Menu } from '../Menu';
import ExpandableSection from './ExpandableSection';
import AppTheme from 'shared/components/AppTheme';
import './styles.css';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import { Skeleton } from '@material-ui/lab';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '25px',
      backgroundColor: '#FFF'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    itemBlock: {
      padding: '15px',
      margin: '7px'
    },
    media: {
      height: 140
    }
  })
);



export default function MenuPage() {
  const classes = useStyles();
  const { get } = useSafeFetch();
  const [menu, setMenu] = React.useState<string>("");
  const [ready, setReady] = React.useState<boolean>(false);
  let { uri } = useParams();

  const fetchMenu = async (uri: string) => {
    try {
      const response = await get(`${process.env.REACT_APP_PORTAL}/menus/${uri}`);
      if (response.ok) {
        const res = await response.json();
        setMenu(res.config);
        setReady(true);
      }
    } catch (e) {
      console.error(e);
      console.error('failed to fetch menu');
    }
  };

  useEffect(()=>{
    fetchMenu(uri);
  },[])



  return (
    <AppTheme>
      { ready ? 
        <Menu menuYml={menu} />
      :
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
          <Skeleton variant='circle' width={window.innerWidth/4.0} height={window.innerWidth/4.0} animation="wave" />
          <Skeleton variant='text' width={3*window.innerWidth/4.0} height={3*window.innerWidth/4.0} animation="wave" />
        </div>
      }
    </AppTheme>
  );
}
