import React from 'react';
import { AppBar as MuiAppBar, Toolbar, Button, Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Logo from 'giftcards/assets/logo/flatlogo.svg';
import { isLocal } from 'shared/utils/DevUtils';
import MenuSwitcher from '../../../MenuSwitcher';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';

const AppBar: React.FC = () => {
  const classes = useStyles();
  const [openEl, setOpenEl] = React.useState<boolean>(false);
  const buttonRef = React.useRef<HTMLButtonElement | null>();

  const handleClick = () => {
    setOpenEl(!openEl);
  };
  const close = () => setOpenEl(false);

  return (
    <MuiAppBar position='fixed' className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarStart}>
          <a href='/' style={{ paddingRight: '10px' }}>
            <img src={Logo} alt='Paneau' className={classes.logo} />
          </a>
          <Button
            ref={ref => (buttonRef.current = ref)}
            className={classes.bar}
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
          >
            Products
            <ArrowDropDownIcon className={openEl ? classes.open : classes.close} />
          </Button>
        </div>

        <Button role='link' onClick={logout} className={classes.logout}>
          Logout
        </Button>
      </Toolbar>
      <Grid
        className={openEl ? classes.grid : classes.hidden}
        container
        spacing={4}
        alignItems='stretch'
        direction='row'
        justify='space-between'
      >
        <Grid className={classes.gridItem} item xs={4}>
          <h3 className={classes.menuHeader}>Transactions</h3>
          <RouterLink href='/' close={close} className={classes.menuitem}>
            Gift Cards
          </RouterLink>
          <RouterLink href='/menus' close={close} className={classes.menuitem}>
            Menus
          </RouterLink>
        </Grid>
        <Grid className={classes.gridItem} item xs={4} style={{ height: '100%' }}>
          <h3 className={classes.menuHeader}>Marketing</h3>
          <a className={classes.menuitem}>
            {'>'}Advertise <br></br> (coming soon)
          </a>
          <HardLink href='/designer' close={close} className={classes.menuitem}>
            Designer
          </HardLink>
        </Grid>
        <Grid className={classes.gridItem} item xs={4}>
          <h3 className={classes.menuHeader}>Accounts</h3>
          <a className={classes.menuitem}>
            {'>'}Profile <br></br> (coming soon)
          </a>
        </Grid>
      </Grid>
    </MuiAppBar>
  );
};

const logout = async () => {
  try {
    const domain = isLocal() ? process.env.REACT_APP_PORTAL : '';
    await fetch(`${domain}/api/logout`, { method: 'GET', credentials: 'include' });
  } catch (e) {
    console.error(e);
    console.error('logout failure');
  }
  if (typeof window !== 'undefined') {
    window.location.href = '/login';
  }
};

const RouterLink: React.FC<{ href: string; className: string; close: () => void }> = props => (
  <Link to={props.href}>
    <MenuItem className={props.className} onClick={props.close}>
      {props.children}
    </MenuItem>
  </Link>
);

const HardLink: React.FC<{ href: string; className: string; close: () => void }> = props => (
  <a href={props.href}>
    <MenuItem className={props.className} onClick={props.close}>
      {props.children}
    </MenuItem>
  </a>
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1600
    },
    toolbar: {
      justifyContent: 'space-between'
    },
    toolbarStart: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    logo: {
      height: '2rem',
      minHeight: '2rem'
    },
    logout: {
      color: '#FFF !important'
    },
    bar: {
      color: '#FFF !important',
      marginLeft: '0.5rem'
    },
    open: {
      transform: 'rotate(-90deg)',
      transition: '.25s'
    },
    close: {
      transition: '.25s'
    },
    menuHeader: {
      borderBottom: '1px solid #ccc',
      padding: '0 1rem',
      color: '#FFFFFF'
    },
    menuitem: {
      'color': '#FFFFFF',
      '&:hover': {
        backgroundColor: '#303e50',
        transform: 'translateY(-1px)'
      }
    },
    grid: {
      padding: '0 1.5rem 1resm',
      color: '#FFFFFF',
      a: {
        color: '#FFFFFF'
      }
    },
    hidden: {
      display: 'none'
    },
    gridItem: {
      width: '11rem'
    }
  })
);

export default AppBar;
