import { Paper, Typography, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Menu } from '../../../giftcards/Menu';

interface MenuProps {
  menuYml: string;
}
const MenuPreview: React.FC<MenuProps> = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      {/* <div className={classes.pill}>Preview</div> */}
      {/* <div className={classes.paperHead}></div> */}
      <div className={classes.previewContainer}>
        <Menu menuYml={props.menuYml} />
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    pill: {
      top: 0,
      fontSize: '0.75rem',
      color: '#FFF',
      fontWeight: 700,
      marginRight: '-43px',
      right: '50%',
      padding: '0.25rem 0.75rem',
      position: 'absolute',
      borderRadius: '5px',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      textTransform: 'uppercase',
      backgroundColor: '#8e8e8e94'
    },
    paperHead: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(2)
    },
    previewContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    skeleton: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '310px',
      height: '450px',
      padding: theme.spacing(2)
    },
    qrSkeleton: {
      width: '150px',
      height: '150px',
      alignSelf: 'center',
      marginBottom: theme.spacing(2)
    }
  })
);

export default MenuPreview;
