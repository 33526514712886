import { Paper, Typography, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

interface TotalValueCardProps {
  total: number | undefined;
  symbol: string;
  stripeUrl: string;
}

const TotalValueCard: React.FC<TotalValueCardProps> = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHead}>
        <Typography variant='h5' component='h2'>
          Revenue
        </Typography>
      </div>
      <div className={classes.content}>
        {props.total === undefined ? (
          <CircularProgress size='4rem' />
        ) : (
          <div className={classes.totalContainer}>
            <Typography variant='h2' component='h2'>
              {props.symbol}
              {props.total}
            </Typography>
            <Typography variant='subtitle1' component='h2'>
              Revenue After Fees
            </Typography>
          </div>
        )}
        <a href={props.stripeUrl} target='_blank' rel='noopener noreferrer' className={classes.link}>
          <Button variant='contained'>
            Open Stripe <OpenInNewIcon fontSize='small' className={classes.openInNew} />
          </Button>
        </a>
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: '231px',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      flexGrow: 1
    },
    paperHead: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    link: {
      textDecoration: 'none'
    },
    openInNew: {
      marginLeft: theme.spacing(1)
    },
    totalContainer: {
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'center',
      padding: theme.spacing(2)
    }
  })
);

export default TotalValueCard;
