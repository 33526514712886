import React from 'react';
import { Container, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

interface StripePageProps {
  url: string;
}

const StripePage: React.FC<StripePageProps> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Container className={classes.root}>
        <h1>Set Up Payment Information</h1>
        <p>
          We'll deposit directly into your account whenever a gift card is purchased. Because payment will go directly
          into your account, we require bank information before you launch your gift card page.
        </p>
        <p>
          <b>
            Paneau normally charges a 3% fee for each transaction, but we're currently waiving all fees in light of the
            COVID-19 pandemic.
          </b>{' '}
          {/* This fee will be applied to the transaction amount but not deducted from the gift card value. For example, a
          gift card purchased for $10 will retain a value of $10 and $9.70 will be credited to your Stripe account. */}
        </p>
        <div className={classes.linkContainer}>
          <a href={props.url} className={classes.link}>
            <Button variant='contained' color='secondary'>
              Connect Bank
              <OpenInNewIcon fontSize='small' className={classes.openInNew} />
            </Button>
          </a>
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: '2rem 0',
    },
    alert: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    buttonBar: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    linkContainer: {
      marginTop: theme.spacing(4),
      textAlign: 'center',
    },
    link: {
      'textDecoration': 'none',
      '& > button': {
        width: '250px',
      },
    },
    openInNew: {
      marginLeft: theme.spacing(1),
    },
  })
);

export default StripePage;
