import { Stepper, Step, StepLabel, Container } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import StripePage from './StripePage';
import EditOffering from 'giftcards/common/components/EditOffering';
import { OfferingsContext } from 'giftcards/core/context/OfferingsContext';
import { StripeContext } from 'giftcards/core/context/StripeContext';

const Setup: React.FC = () => {
  const offeringsContext = useContext(OfferingsContext);
  const stripeContext = useContext(StripeContext);
  const classes = useStyles();

  const activeStep = offeringsContext.setup ? 1 : 0;

  return (
    <Container className={classes.root}>
      <Stepper className={classes.stepper} alternativeLabel activeStep={activeStep}>
        <Step key='offering' active={0 === activeStep} completed={offeringsContext.setup}>
          <StepLabel>Create your gift card</StepLabel>
        </Step>
        <Step key='stripe' active={1 === activeStep} completed={stripeContext.setup}>
          <StepLabel>Setup deposit information</StepLabel>
        </Step>
      </Stepper>
      {activeStep === 0 ? (
        <EditOffering
          create
          done={offeringsContext.refreshOfferings}
          title='Set Up Your Gift Card'
          showSnackbar={false}
        />
      ) : (
        <StripePage url={stripeContext.url} />
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4)
    },
    stepper: {
      backgroundColor: 'transparent !important'
    }
  })
);

export default Setup;
