import React, { useState, useContext } from 'react';
import QrReader from 'react-qr-reader';
import { Typography, TextField, Button, makeStyles, Theme } from '@material-ui/core';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import GiftCard from 'giftcards/common/types/GiftCard';
import { SnackbarContext } from 'giftcards/core/context/SnackbarContext';
import LoadingButton from 'shared/components/LoadingButton';
import { useHistory } from 'react-router-dom';

interface CodeCaptureProps {
  setCard: (card: GiftCard) => void;
}

const CodeCapture: React.FC<CodeCaptureProps> = props => {
  const [code, setCode] = useState<string>();
  const [error, setError] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const { showSnackbarAlert } = useContext(SnackbarContext);
  const [qrEnabled, setQrEnabled] = useState(true);
  const { get } = useSafeFetch();
  const classes = useStyles();
  const history = useHistory();

  const codeSubmit = () => getCard(code);
  const cancel = () => {
    history.push('/');
    window.scrollTo(0, 0);
  };

  const getCard = async (code: string | undefined | null) => {
    if (!code) return;
    try {
      setLoading(true);
      const response = await get(`${process.env.REACT_APP_PORTAL}/card?short_code=${code}`);
      if (!response.ok) {
        setError(true);
        showSnackbarAlert('Invalid code. Please try again.', 'error');
      } else {
        props.setCard(await response.json());
      }
    } catch (e) {
      if (!e.cancelled) {
        setLoading(false);
        setError(true);
        showSnackbarAlert('Something went wrong. Please try again.', 'error');
        console.error(e);
      }
    }
  };

  const onInput = (event: any) => {
    const code = event.target.value;
    setCode(code);
  };

  const handleQrError = (e: any) => {
    setQrEnabled(false);
    console.error(e);
  };

  return (
    <>
      {qrEnabled ? (
        <>
          <Typography variant='subtitle1' color='textSecondary' gutterBottom>
            Scan or Enter Code
          </Typography>
          <QrReader delay={200} onError={handleQrError} onScan={getCard} className={classes.qrScanner} />
          <Typography className={classes.or} color='textSecondary' gutterBottom>
            — or —
          </Typography>
        </>
      ) : (
        <Typography className={classes.or} color='textSecondary' gutterBottom>
          This device is not able to access the camera from the browser to scan a QR code. Please enter the short code
          from the gift card to continue.
        </Typography>
      )}
      <div className={classes.shortCodeForm}>
        <TextField
          className={classes.chargeField}
          error={Boolean(error)}
          id='outlined-basic'
          label='Short Code'
          variant='outlined'
          color='secondary'
          onInput={onInput}
        />
        <LoadingButton loading={loading} disabled={!code} color='secondary' variant='contained' onClick={codeSubmit}>
          Submit
        </LoadingButton>
      </div>
      <div className={classes.actionBar}>
        <Button onClick={cancel}>Cancel</Button>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  or: {
    padding: theme.spacing(3),
    fontSize: 14,
    margin: 0
  },
  chargeField: {
    minWidth: 100
  },
  qrScanner: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      width: '256px'
    },
    [theme.breakpoints.up('md')]: {
      width: '600px'
    }
  },
  actionBar: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  shortCodeForm: {
    'display': 'flex',
    'alignItems': 'center',
    '& > button': {
      marginLeft: theme.spacing(1)
    }
  }
}));

export default CodeCapture;
