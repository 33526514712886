import { Paper, Typography, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React from 'react';

interface CardCountCardProps {
  active?: number;
  total?: number;
}

const CardCountCard: React.FC<CardCountCardProps> = props => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.paperHead}>
        <Typography variant='h5' component='h2'>
          Gift Card Counts
        </Typography>
      </div>
      <div className={classes.content}>
        {props.active === undefined ? (
          <CircularProgress size='4rem' />
        ) : (
          <>
            <div className={classes.totalContainer}>
              <Typography variant='h2' component='h2'>
                {props.active}
              </Typography>
              <Typography variant='subtitle1' component='h2'>
                Active Cards
              </Typography>
            </div>
            <Divider orientation='vertical' className={classes.vertDivider} flexItem />
            <div className={classes.totalContainer}>
              <Typography variant='h2' component='h2'>
                {props.total}
              </Typography>
              <Typography variant='subtitle1' component='h2'>
                Total Cards
              </Typography>
            </div>
          </>
        )}
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: '231px',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1
    },
    paperHead: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    chartContainer: {
      flexShrink: 1,
      flexGrow: 1
    },
    totalContainer: {
      flexGrow: 0,
      flexShrink: 0,
      textAlign: 'center',
      padding: theme.spacing(2)
    },
    vertDivider: {
      margin: theme.spacing(3)
    }
  })
);

export default CardCountCard;
