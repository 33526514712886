import { Button, Grid, InputLabel, Typography, Paper, FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React, { useState, useContext, useEffect } from 'react';
import GiftCardOffering from 'giftcards/common/types/GiftCardOffering';
import Menu from 'giftcards/common/types/Menu';
import BootstrapInput from 'shared/components/BootstrapInput';
import ImageUpload, { Image } from 'shared/components/ImageUpload';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import ColorPicker from '../../../../shared/components/ColorPicker';
import OfferingPreview from '../../../../shared/components/OfferingPreview';
import { red } from '@material-ui/core/colors';
import { OfferingsContext } from 'giftcards/core/context/OfferingsContext';
import { SnackbarContext } from 'giftcards/core/context/SnackbarContext';
import LoadingButton from 'shared/components/LoadingButton';
import yaml from 'js-yaml';
import TextField from '@material-ui/core/TextField';
import * as Joi from 'typesafe-joi';
import MenuPreview from '../../../../shared/components/MenuPreview';
import Editor from 'react-simple-code-editor';

// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-yaml';

interface MenuPageProps {
  menuYml?: string;
  save: (menuYml: string) => void;
  title: string;
  create?: boolean;
  showSnackbar?: boolean;
  doneLabel?: string;
  hideCancel?: boolean;
}

const MenuItemValidation = Joi.object({
  description: Joi.string(),
  price: [Joi.string(), Joi.number()],
  image: Joi.string().uri()
});

const MenuSubsectionValidation = Joi.object({
  description: Joi.string(),
  image: Joi.string().uri()
}).pattern(/.*/, MenuItemValidation.allow(null));

const MenuSectionValidation = Joi.object({}).pattern(/.*/, MenuSubsectionValidation.allow(null));

const MenuValidation = Joi.object({
  title: Joi.string().required(),
  subtitle: Joi.string(),
  hours: Joi.string(),
  website: Joi.string().uri(),
  logo: Joi.string().uri(),
  sections: MenuSectionValidation.required()
});

const DEFAULT_MENU_YML: string = `title: My Restaurant
subtitle: Delightful!
hours: 3-6PM
website: https://eater.com
logo: https://www.101domain.com/images/flags/large/RESTAURANT.png
sections:
    Appetizers:
        Wings:
            description: Crispy fried chicken wings and creany & tangy sauce
            price: 8
            image: https://i.ibb.co/7VNmTHJ/2018-02-03-sticky-spicy-baked-chicken-wings-2.jpg
        Hummus & Pita:
            description: Topped with roasted pin nuts and extra virgin olive oil
            image: https://i.ibb.co/RYkjgNX/Homemade-Hummus-and-Pita-Chips-4.jpg
            price: 7
    Entrées:
        Pork Belly Street Taco:
            image: https://i.ibb.co/R4y6YGq/INSTANT-POT-STREET-TACOS.jpg
            description: Pork belly, salsa, & cotija cheese
            price: 12
    Drinks:
        Mohito:
            price: 8
            image: https://i.ibb.co/Kz5CSV4/mojito-5a8f339fba61770036ec61d8.jpg 
            description: white rum, fresh lime, and mint`;

const validateYml = (menuYml: string): { isError: boolean; errors: string[] } => {
  let isError = false;
  let errors: string[] = [];
  try {
    const rawYml = yaml.safeLoad(menuYml);
    if (rawYml == undefined) {
      return { isError, errors };
    }
    if (typeof rawYml === 'string') {
      throw new yaml.YAMLException('Invalid notation');
    }
    const res = MenuValidation.validate(rawYml, { abortEarly: false });
    if (res.error != null) {
      isError = true;
      errors = errors.concat(res.error.details.map(e => e.message));
    }
  } catch (error) {
    if (error instanceof yaml.YAMLException) {
      isError = true;
      errors.push(error.message);
    } else {
      throw error;
    }
  }

  return { isError, errors };
};

const EditMenu: React.FC<MenuPageProps> = props => {
  const classes = useStyles();
  const { post, put } = useSafeFetch();
  const { showSnackbarAlert } = useContext(SnackbarContext);
  const [menuYml, setMenuYml] = useState<string>(props.menuYml || DEFAULT_MENU_YML);
  const [validMenuYml, setValidMenuYml] = useState<string>(props.menuYml || DEFAULT_MENU_YML);
  const [menuShortCode, setShortCode] = useState<string>('');
  const [error, setError] = useState(false);
  const [didEdit, setDidEdit] = useState(false);
  const [ymlErrors, setYmlErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.menuYml != undefined) {
      updateMenuYml(props.menuYml);
    }
  }, [props.menuYml]);

  const done = () => {
    props.save(menuYml);
    setDidEdit(false);
    // window.scrollTo(0, 0);
  };

  const onSubmit = async () => {
    const hasErrors = !!ymlErrors.length;
    if (hasErrors) {
      showSnackbarAlert('Can not save with errors', 'error');
      return;
    }
    done();
  };

  const updateMenuYml = (value: string) => {
    setMenuYml(value);
    const { isError, errors } = validateYml(value);
    if (!isError) {
      setValidMenuYml(value);
      setYmlErrors([]);
    } else {
      setYmlErrors(errors);
    }
  };

  const onCodeFieldChanged = (value: string) => {
    setDidEdit(true);
    updateMenuYml(value);
  };

  return (
    <>
      {error && <Alert severity='error'>Something went wrong. Try again.</Alert>}
      {didEdit && <Alert severity='warning'>Unsaved changes</Alert>}
      <Typography variant='h4' component='h1' className={classes.title}>
        {props.title}
      </Typography>
      <p className={classes.center}>A simple way to make changes to your menu</p>
      <Grid container className={classes.grid} spacing={8} justify='center'>
        <Grid item>
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <InputLabel className={classes.label} htmlFor='offering-text'>
                Menu Markup
              </InputLabel>
              <Editor
                className={classes.ymlEditor}
                value={menuYml}
                onValueChange={onCodeFieldChanged}
                highlight={code => highlight(code, languages.yaml)}
                padding={10}
              />
              <FormHelperText error={!!ymlErrors.length} id='component-helper-text'>
                {ymlErrors.join(', ')}
              </FormHelperText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <MenuPreview menuYml={validMenuYml} />
        </Grid>
      </Grid>
      <div className={classes.buttonBar}>
        {!Boolean(props.hideCancel) && <Button onClick={done}>Cancel</Button>}
        <LoadingButton loading={loading} onClick={onSubmit} variant='contained' color='secondary'>
          {props.doneLabel || 'Save'}
        </LoadingButton>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    center: {
      textAlign: 'center'
    },
    edit: {
      flex: 1
    },
    title: {
      paddingTop: theme.spacing(2),
      textAlign: 'center',
      margin: '0 auto'
    },
    preview: {
      flex: 1,
      width: '100%',
      height: '100%'
    },
    grid: {
      padding: theme.spacing(4)
    },
    divider: {
      marginRight: theme.spacing(4)
    },
    label: {
      fontWeight: 700,
      fontSize: '0.9rem'
    },
    buttonGroup: {
      'marginLeft': theme.spacing(2),
      '.MuiToggleButton-root': {
        height: '2rem'
      }
    },
    ymlEditor: {
      fontFamily: '"Fira code", "Fira Mono", monospace',
      fontSize: 12,
      border: '1px solid #ced4da',
      borderRadius: '4px',
      marginTop: theme.spacing(1)
    },
    buttonBar: {
      'display': 'flex',
      'justifyContent': 'flex-end',
      'marginBottom': '2rem',
      '& button': {
        margin: theme.spacing(1)
      }
    },
    input: {
      'width': '300px',
      '& > input': {
        width: '100%',
        minHeight: 'unset!important'
      },
      '& > textarea': {
        width: '100%',
        height: '82px !important'
      }
    },
    errorText: {
      color: red[500],
      marginTop: theme.spacing(1)
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    logoThumbnailPaper: {
      marginRight: theme.spacing(2),
      height: '50px',
      width: '50px'
    },
    logoThumbnail: {
      height: '50px',
      width: '50px',
      objectFit: 'contain'
    },
    imageLogoRow: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(1)
    },
    removeButton: {
      width: '50px',
      minWidth: '50px',
      lineHeight: '11px',
      height: '20px',
      fontSize: '10px',
      fontWeight: 700,
      marginTop: '5px'
    },
    colorsRow: {
      'display': 'flex',
      '& > div': {
        paddingRight: theme.spacing(2),
        textAlign: 'center'
      }
    }
  })
);

export default EditMenu;
