import React from 'react';
import { Button, makeStyles, Theme, createStyles } from '@material-ui/core';
import QrCode from '../assets/qr-code.svg';
import { useHistory } from 'react-router-dom';

const ChargeButton: React.FC = props => {
  const { ...other } = props;
  const history = useHistory();
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    history.push('/chargecard');
  };

  return (
    <div {...other}>
      <Button onClick={handleClick} className={classes.button} variant='contained' color='secondary'>
        <img src={QrCode} alt='Scan QR Code' className={classes.icon} />
        Charge Gift Card
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: '4.3rem'
    },
    icon: {
      width: '50px',
      marginRight: theme.spacing(1)
    }
  })
);

export default ChargeButton;
