import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
  DialogActions,
  Button
} from '@material-ui/core';
import React from 'react';

interface ConfirmTransactionDialogProps {
  open: boolean;
  transactionRemainder: string;
  transactionCharged: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmTransactionDialog: React.FC<ConfirmTransactionDialogProps> = props => {
  return (
    <Dialog
      open={props.open}
      onClose={() => {}}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Not enough funds</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <Typography color={'error'}>Customer owes: ${props.transactionRemainder}</Typography>
          Only ${props.transactionCharged} will be charged to the giftcard. <br /> The remainder ($
          {props.transactionRemainder}) must be charged separately.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancel}>Cancel</Button>
        <Button onClick={props.onConfirm} color='secondary' variant='contained' autoFocus>
          Charge ${props.transactionCharged}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmTransactionDialog;
