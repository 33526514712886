import React, { useContext, useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { CartContext } from '.';
import { Grid } from '@material-ui/core';
import "./ItemSelectionList.css"
import { string } from 'yup';
import {FoodItemProps} from "./FoodItem";

import './ItemSelectionList.css';
import { toASCII } from 'punycode';

interface FoodItems {
    info: FoodItemProps;
    qty: number;
}

export const ItemSelectionList: React.FC = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<any[]>([]);
  var totalCost = 0;

  // Context from Menu
  const { cart: myCart } = useContext(CartContext);

    const sortedCart: {[id:string]: FoodItems} = {};
    myCart.map( (item) => {
        if(sortedCart[item.label] !== undefined) {
            sortedCart[item.label].qty += 1;
        } else {
            // sortedCart
            sortedCart[item.label] = {
                info: {
                    bgImage: item.image,
                    header: item.label,
                    body1: item.description as string,
                    price: item.price
                },
                qty: 1
            }
        }
    } );
    
    for (let index = 0; index < myCart.length; index++) {
        totalCost += myCart[index].price || 0;
    }

    return(

        <>

        { myCart.length > 0 ? 
        
        <Grid container >
            <Grid item xs={1}></Grid>
            <Grid item xs={10} className={"your-selection"}>
            <p>This is my selection list</p>

            { Object.keys(sortedCart).map((key, index) => (
            
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p>{sortedCart[key].qty>1?`(${sortedCart[key].qty}) ${sortedCart[key].info.header}`: `${sortedCart[key].info.header}`}</p>
                    <p>{`${sortedCart[key].info.price}`}</p>
                </div>
            ))}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <p>Total Cost:</p>
                <p>{(Math.round(totalCost * 100) / 100).toFixed(2)}</p>
            </div>
            </Grid>
            <Grid item xs={1}></Grid>
        </Grid>
        : ""}
        </>
    );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '25px',
      backgroundColor: '#FFF'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    },
    itemBlock: {
      padding: '15px',
      margin: '7px'
    },
    media: {
      height: 140
    }
  })
);
