import { withStyles, Theme, createStyles, fade, InputBase } from '@material-ui/core';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(1)
      }
    },
    input: {
      'borderRadius': 4,
      'position': 'relative',
      'backgroundColor': theme.palette.common.white,
      'border': '1px solid #ced4da',
      'fontSize': theme.typography.fontSize,
      'width': 'auto',
      'padding': '10px 12px',
      'transition': theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      'fontFamily': [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  })
)(InputBase);

export default BootstrapInput;
