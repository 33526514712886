import { Popover } from '@material-ui/core';
import { ChromePicker, ColorChangeHandler } from 'react-color';
import React from 'react';

interface SwatchesPopoverProps {
  open: boolean;
  anchorEl: any;
  onChange: ColorChangeHandler;
  onClose: () => void;
  value: any;
}

const SwatchesPopover: React.FC<SwatchesPopoverProps> = props => {
  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      onClose={props.onClose}
    >
      <ChromePicker color={props.value} onChange={props.onChange} disableAlpha />
    </Popover>
  );
};

export default SwatchesPopover;
