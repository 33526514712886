import React, { useState } from 'react';
import Chart from 'react-apexcharts';

interface SpendingChartProps {
  total?: number;
  spent?: number;
}

const SpendingChart: React.FC<SpendingChartProps> = props => {
  const [data] = useState({
    options: {
      chart: {
        id: 'spending-chart'
      },
      tooltip: {
        enabled: false
      },
      xaxis: {
        categories: ['Claimed', 'Unclaimed']
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      }
    },
    series: [
      {
        name: 'Total Card Spending',
        data: [props.spent, props.total!! - props.spent!!]
      }
    ]
  });

  return <Chart options={data.options} series={data.series} type='bar' width='100%' height={200} />;
};

export default SpendingChart;
