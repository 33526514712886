import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  colors
} from '@material-ui/core';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';

interface Transaction {
  amount: -45;
  creation_date: string;
  description: string;
}

interface TransactionsTableProps {
  transactions?: Transaction[];
}

const TransactionsTable: React.FC<TransactionsTableProps> = props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant='h5' component='h2' className={classes.title}>
        Transactions
      </Typography>
      {props.transactions?.length === 0 ? (
        <Paper className={classes.noTransactions}>
          <Typography variant='body1' color='textSecondary'>
            No transactions yet.
          </Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label='android enterprise devices'>
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.transactions === undefined ? (
                <TableBodySkeleton />
              ) : (
                props.transactions?.map((transaction, i) => (
                  <TransactionRow {...transaction} key={`transaction-${i}`} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

const TransactionRow: React.FC<Transaction> = props => {
  const classes = useStyles();
  const isDebit = props.amount <= 0;
  return (
    <TableRow hover>
      <TableCell className={isDebit ? classes.debit : classes.credit}>${Math.abs(props.amount)}</TableCell>
      <TableCell title={new Date(props.creation_date).toLocaleString()}>
        {moment(new Date(props.creation_date)).fromNow()}
      </TableCell>
      <TableCell>{props.description}</TableCell>
    </TableRow>
  );
};

const TableBodySkeleton: React.FC = () => (
  <>
    <TableRowSkeleton />
    <TableRowSkeleton />
    <TableRowSkeleton />
    <TableRowSkeleton />
  </>
);

const TableRowSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell>
        <Skeleton animation='wave' className={classes.skeleton} />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' className={classes.skeleton} />
      </TableCell>
      <TableCell>
        <Skeleton animation='wave' className={classes.skeleton} />
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      cursor: 'default'
    },
    title: {
      color: 'rgba(0, 0, 0, 0.87)',
      paddingBottom: theme.spacing(1)
    },
    debit: {
      color: colors.green[600]
    },
    credit: {
      color: colors.red[800]
    },
    skeleton: {
      width: '60%'
    },
    noTransactions: {
      padding: theme.spacing(4),
      textAlign: 'center'
    }
  })
);

export default TransactionsTable;
