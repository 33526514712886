import React, { useContext } from 'react';
import { Paper, makeStyles, createStyles, Theme, Typography, IconButton, Button } from '@material-ui/core';
import CopyButton from 'shared/components/CopyButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from 'react-router-dom';
import ShareIcon from '@material-ui/icons/Share';
import { UserContext } from 'giftcards/core/context/UserContext';
import InfoIcon from '@material-ui/icons/Info';
// TODO move entire file to common

// TODO move to offering specific file
interface OfferingLinkCardProps {
  offeringUri: string | undefined;
  className?: string;
  hideTitle?: boolean;
  hideShareButton?: boolean;
}

export const OfferingLinkCard: React.FC<OfferingLinkCardProps> = props => {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  const shareUrl = `${process.env.REACT_APP_PORTAL}/giftcards/${props.offeringUri}`;
  return (
    <LinkCardBlockable
      url={shareUrl}
      label={'Shareable Gift Card Link:'}
      blockMessage={'The link to your gift card will appear here when your account is approved'}
      shouldBlock={!Boolean(userContext.user?.authorized)}
    />
  );
};

interface LinkCardBlockableProps {
  url: string;
  label: string;
  blockMessage: string;
  className?: string;
  hideTitle?: boolean;
  hideShareButton?: boolean;
  shouldBlock?: boolean;
}

export const LinkCardBlockable: React.FC<LinkCardBlockableProps> = props => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${props.className || ''}`}>
      <Paper className={classes.paper}>
        {!Boolean(props.shouldBlock) ? (
          <LinkCard {...props} />
        ) : (
          // <BlockedContent>The link to your gift card will appear here when your account is approved.</BlockedContent>
          <BlockedContent>{props.blockMessage}</BlockedContent>
        )}
      </Paper>
    </div>
  );
};

interface LinkCardProps {
  label: string;
  url: string;
  className?: string;
  hideTitle?: boolean;
  hideShareButton?: boolean;
}

export const LinkCard: React.FC<LinkCardProps> = props => {
  const classes = useStyles();
  const openUrl = () => window.open(`${props.url}?utm_source=direct_link`, '_blank');
  return (
    <div className={classes.container}>
      {!props.hideTitle && (
        <Typography variant='h5' component='h2' className={classes.title}>
          {props.label}
        </Typography>
      )}
      <div className={classes.content}>
        <div className={classes.urlContainer}>{props.url}</div>
        <IconButton className={classes.openButton} aria-label='Visit gift card page' onClick={openUrl} role='link'>
          <OpenInNewIcon fontSize='small' />
        </IconButton>
        <CopyButton className={classes.button} variant='contained' text={props.url} />
      </div>
      {!props.hideShareButton && (
        <div className={classes.shareButton}>
          <Link to='/share' className={classes.shareLink}>
            <Button variant='contained' color='secondary'>
              <ShareIcon fontSize='small' className={classes.icon} />
              Share
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

const BlockedContent: React.FC = props => {
  const classes = useStyles();
  return (
    <>
      <InfoIcon color='secondary' className={classes.icon} />
      <Typography variant='body1' component='p'>
        {props.children}
      </Typography>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary
    },
    paper: {
      'min-height': '4.3rem',
      'margin': 'auto',
      'padding': theme.spacing(2),
      'display': 'flex',
      'alignItems': 'center',
      '& h2': {
        fontSize: theme.typography.body1.fontSize,
        fontWeight: 700,
        marginRight: theme.spacing(1)
      },
      [theme.breakpoints.down('sm')]: {
        'maxWidth': 'unset',
        '& h2': {
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.h5.fontWeight
        }
      }
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start'
      }
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
      maxWidth: '100%'
    },
    title: {
      flexShrink: 0
    },
    urlContainer: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden'
    },
    openButton: {
      marginRight: `${theme.spacing(2)}px !important`,
      flexGrow: 0,
      flexShrink: 0
    },
    button: {
      flexGrow: 0,
      flexShrink: 0
    },
    shareButton: {
      marginLeft: '10px',
      height: '40px',
      flexShrink: 0,
      alignItems: 'center',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0
      }
    },
    shareLink: {
      textDecoration: 'none !important'
    },
    icon: {
      marginRight: theme.spacing(1)
    }
  })
);
