import React from 'react';
import { CSSTransition } from 'react-transition-group';

interface FaseTransitionProps {
  in?: boolean;
}

const FadeTransition: React.FC<FaseTransitionProps> = props => {
  return (
    <CSSTransition {...props} classNames='fade' timeout={200} mountOnEnter={true} unmountOnExit={true}>
      {props.children}
    </CSSTransition>
  );
};

export default FadeTransition;
