import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#26313F' // '#507AA4' // '#000000' // '#2B628F'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: '#1976d2',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffffff'
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    text: {
      primary: '#333'
    },
  },
  // typography: {
  //   fontFamily: ['fieldwork', 'sans-serif', 'Roboto'].join(',')
  // },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 700
      }
    },
    MuiCardHeader: {
      title: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }
    },
    MuiStepIcon: {
      active: {
        color: '#1976d2 !important'
      },
      completed: {
        color: '#1976d2 !important'
      }
    }
  }
});

const AppTheme: React.FC = props => {
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

export default AppTheme;
