import useSafeFetch from 'shared/hooks/useSafeFetch';
import React, { useState, useRef, useEffect } from 'react';
import Menu from 'giftcards/common/types/Menu';
export interface IMenu {
  uuid: string;
  config: string;
  business_id: string;
  uri: string;
  creation_date: string;
  customer_setup: boolean;
}

interface MenuContextType {
  menus: IMenu[] | undefined;
  refreshMenus: () => void;
  loading: boolean;
  error: boolean;
  setup: boolean;
}

export const MenusContext = React.createContext<MenuContextType>({
  menus: [],
  setup: false,
  refreshMenus: () => null,
  error: false,
  loading: false
});

interface State {
  menus: IMenu[] | undefined;
  setup: boolean;
  error: boolean;
}

const MenusContextProvider: React.FC = props => {
  const [state, setState] = useState<State>({
    menus: undefined,
    setup: false,
    error: false
  });
  const { get } = useSafeFetch();

  const refreshMenus = useRef(async () => {
    if (state.menus !== undefined || state.error) {
      setState({ setup: false, menus: undefined, error: false });
    }
    try {
      const response = await get(`${process.env.REACT_APP_PORTAL}/menus`);
      const menu = await response.json();
      const setup = Boolean(menu?.length) && !(menu?.[0].configured === false);
      setState(s => ({ ...s, menus: menu, setup }));
    } catch (e) {
      console.error(e);
      if (!e.cancelled && !e.unauthenticated) {
        setState(s => ({ ...s, error: true }));
      }
    }
  });

  useEffect(() => {
    refreshMenus.current();
  }, []);

  return (
    <MenusContext.Provider
      value={{
        menus: state.menus,
        setup: state.setup,
        refreshMenus: refreshMenus.current,
        error: state.error,
        loading: state.menus === undefined && !state.error
      }}
    >
      {props.children}
    </MenusContext.Provider>
  );
};

export default MenusContextProvider;
