import { makeStyles, Theme, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem 0'
    },
    center: {
      textAlign: 'center'
    },
    recaptchaField: {
      '& > div': {
        margin: '0 auto'
      }
    },
    error: {
      color: '#ff4136',
      fontSize: '1rem'
    },
    textField: {
      maxWidth: '100%',
      width: '280px',
      minHeight: '4.5rem'
    },
    button: {
      marginTop: '1rem'
    }
  })
);
