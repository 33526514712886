import React, { useState, useRef } from 'react';
import { Tooltip, Fade, Button, ButtonProps } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface CopyButton extends ButtonProps {
  text: string;
}

const CopyButton: React.FC<CopyButton> = props => {
  const { text, ...buttonProps } = props;
  const [showCopyToolTip, setShowCopyTooltip] = useState(false);
  const closeTimeout = useRef<any>();
  const openCopyTooltip = () => {
    clearTimeout(closeTimeout.current);
    setShowCopyTooltip(true);
    closeTimeout.current = setTimeout(closeCopyTooltip, 1000);
  };
  const closeCopyTooltip = () => setShowCopyTooltip(false);

  return (
    <Tooltip
      PopperProps={{ disablePortal: true }}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      onClose={closeCopyTooltip}
      open={showCopyToolTip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title='Copied'
    >
      <CopyToClipboard text={props.text} onCopy={openCopyTooltip}>
        <Button onClick={openCopyTooltip} {...buttonProps}>
          Copy
        </Button>
      </CopyToClipboard>
    </Tooltip>
  );
};

export default CopyButton;
