import { Container, Grid, withWidth, isWidthUp, WithWidthProps } from '@material-ui/core';
import { makeStyles, createStyles, Theme as MuiTheme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';

const urls = {
  snackthis:
    '<iframe src="https://snackthis.co/paneau" style="width:100%; height:800px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>'
};

interface iframeProps {
  iframe: string;
}

function Iframe(props: iframeProps) {
  return <div dangerouslySetInnerHTML={{ __html: props.iframe }} />;
}

interface DesignerState {
  test: string;
}

const Designer: React.FC = props => {
  const classes = useStyles();
  const [attr, setAttr] = useState<DesignerState>();

  return (
    <Container className={classes.root}>
      <h1>Content Designer</h1>
      <Iframe iframe={urls['snackthis']} />,
    </Container>
  );
};

const useStyles = makeStyles((theme: MuiTheme) =>
  createStyles({
    root: {
      padding: '2rem 0',
      backgroundColor: '#FFF'
    },
    alert: {
      marginBottom: theme.spacing(2)
    }
  })
);

export default withWidth()(Designer);
