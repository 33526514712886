import React from 'react';
import { IconButton, IconButtonProps } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

interface BackButtonProps extends IconButtonProps {
  back: () => void;
}

const BackButton: React.FC<BackButtonProps> = props => {
  const { back, ...other } = props;
  return (
    <IconButton aria-label='back' onClick={back} {...other}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
