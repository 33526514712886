export const ShareButtonHTML = (uri: string) => `
<a style="text-decoration: none" href="${process.env.REACT_APP_PORTAL}/giftcards/${uri}"><div style="padding: 8px 16px; box-shadow: 0px 1px 3px -1px rgba(0,0,0,0.2), 0px 3px 5px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12); width: 200px; border-radius: 5px; font-size: 16px; font-family: Helvetica, serif; letter-spacing: 0; line-height: 1.5; font-weight: 600; text-align: center;">
<div style="font-size: 16px; font-weight: 600">Purchase a Gift Card</div>
<svg width='50%' viewBox='10 50 440 267'>
<path d='m55.996094 64.484375h352c17.671875 0 32 14.324219 32 32v176c0 17.671875-14.328125 32-32 32h-352c-17.671875 0-32-14.328125-32-32v-176c0-17.675781 14.328125-32 32-32zm0 0' fill='#cce3ff'></path>
<path d='m407.996094 64.484375-240 240h240c17.671875 0 32-14.328125 32-32v-176c0-17.675781-14.328125-32-32-32zm0 0' fill='#bad9ff'></path>
<path d='m439.996094 176.484375h-296v-112h-16v112h-104v16h104v112h16v-112h296zm0 0' fill='#b770d0'></path>
<path d='m151.308594 188.820312 45.488281-18.257812c14.515625-5.753906 23.296875-20.601562 21.351563-36.09375-1.945313-15.496094-14.128907-27.707031-29.617188-29.695312-15.484375-1.988282-30.355469 6.753906-36.152344 21.253906l-15.238281 38.253906-15.199219-38.253906c-5.828125-14.425782-20.664062-23.097656-36.09375-21.097656-15.429687 2-27.558594 14.167968-29.515625 29.601562-1.957031 15.4375 6.757813 30.246094 21.199219 36.03125l45.449219 18.257812-2.167969 2.175782c-15.007812 15.167968-35.480469 23.675781-56.816406 23.605468v16c25.566406.058594 50.09375-10.140624 68.078125-28.3125l5.035156-5.054687 5.046875 5.0625c17.980469 18.171875 42.5 28.367187 68.0625 28.304687v-16c-21.308594.050782-41.75-8.457031-56.734375-23.605468zm15.886718-56.867187c3.113282-7.664063 11.007813-12.257813 19.210938-11.183594 8.203125 1.078125 14.648438 7.554688 15.679688 15.761719s-3.609376 16.078125-11.289063 19.152344l-39.34375 15.800781zm-83.769531 23.730469c-7.753906-3.035156-12.464843-10.9375-11.453125-19.203125 1.011719-8.265625 7.492188-14.796875 15.75-15.871094 8.257813-1.074219 16.195313 3.582031 19.289063 11.3125l15.761719 39.5625zm0 0' fill='#e088ff'></path>
<g fill='#0c59a6'><path d='m375.996094 96.484375h16v32h-16zm0 0'></path><path d='m343.996094 96.484375h16v32h-16zm0 0'></path><path d='m311.996094 96.484375h16v32h-16zm0 0'></path><path d='m279.996094 96.484375h16v32h-16zm0 0'></path><path d='m375.996094 248.484375h16v16h-16zm0 0'></path><path d='m343.996094 248.484375h16v16h-16zm0 0'></path><path d='m311.996094 248.484375h16v16h-16zm0 0'></path><path d='m279.996094 248.484375h16v16h-16zm0 0'></path></g>
</svg>
<div style="font-size: 9px; font-weight: 400">powered by Paneau</div>
</div>
</a>
`;
