import React, { useState, useEffect, useContext } from 'react';
import useSafeFetch from 'shared/hooks/useSafeFetch';
import { OfferingsContext } from 'giftcards/core/context/OfferingsContext';

const CardsTable: React.FC = () => {
  const [, setCards] = useState();
  const [, setError] = useState<string>();
  const { get } = useSafeFetch();
  const { offerings } = useContext(OfferingsContext);

  useEffect(() => {
    // fetchCards();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchCards = async () => {
    if (offerings?.[0]?.uuid) {
      try {
        const response = await get(`/offerings/${offerings?.[0]?.uuid}/card`);
        const json = await response.json();
        console.log('cards');
        console.log(json);
        setCards(json);
      } catch (e) {
        if (!e.cancelled) {
          setError('An offering must be selected to show gift cards.');
        }
      }
    } else {
      setError('An offering must be selected to show gift cards.');
    }
  };

  return null;
};

export default CardsTable;
