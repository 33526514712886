import React, { useContext, useState } from 'react';
import { Form, FastField, Formik } from 'formik';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
// import Recaptcha from 'react-google-recaptcha';
import * as Yup from 'yup';
import { SignUpFormContext, CreateAccountResponseBody } from './SignUpFormContext';
import { TextField } from '@material-ui/core';
import LoadingButton from 'shared/components/LoadingButton';
import { useStyles } from 'giftcards/auth/styles';

interface BusinessFormProps {
  type: 'business' | 'demo';
  onAccountCreated: (account: CreateAccountResponseBody) => void;
}

const DEFAULT_ERR_MSG = 'An error occurred. Please try again.';

const BusinessForm: React.FC<BusinessFormProps> = ({ type, onAccountCreated }) => {
  const classes = useStyles();
  const formContext = useContext(SignUpFormContext);
  const [errorOnSubmit, setErrorOnSubmit] = useState<string>();

  const handleChange = (prop: string, props: any) => (e: any) => {
    props.handleChange(e);
    formContext.updateValue(prop, e.target.value);
  };

  return (
    <Formik
      initialValues={{
        ...formContext.value
        // recaptcha: ''
      }}
      validationSchema={() => {
        let schema: any = {
          businessname: Yup.string().required('Business name field is required'),
          email: Yup.string()
            .email('Invalid email')
            .required('Email field is required'),
          phone: Yup.string()
            .required('Phone number field is required')
            .test('phone-valid', 'Phone number must be valid.', function(value) {
              return value && !!parsePhoneNumberFromString(value, 'US')?.isValid();
            }),
          password: Yup.string()
            .required('Password field is required')
            .matches(/.{8,}/, 'The password must be at least 8 characters long.')
          // recaptcha: Yup.string()
          //   .required("Please verify you're not a robot!")
          //   .nullable()
        };
        if (type === 'demo') {
          schema = {
            ...schema,
            name: Yup.string().required('Name field is required.')
          };
        }
        return Yup.object().shape(schema);
      }}
      onSubmit={async ({ businessname, email, phone, password }, { setSubmitting }) => {
        try {
          setSubmitting(true);
          setErrorOnSubmit('');
          if (formContext.activeForm === 'business') {
            const response: Response = await formContext.createAccount({
              businessname,
              email,
              phone,
              password
            });
            if (!response.ok) {
              setSubmitting(false);
              setErrorOnSubmit(getErrorMessageByStatus(response));
            } else {
              const accountInfo: CreateAccountResponseBody = await response.json();
              onAccountCreated(accountInfo);
            }
          } else if (formContext.activeForm === 'demo') {
            await formContext.requestDemo({ businessname, email, phone });
          }
        } catch (err) {
          console.error(err);
          setSubmitting(false);
          setErrorOnSubmit('');
        }
      }}
    >
      {props => {
        const businessNameError = props.touched.businessname && props.errors.businessname;
        const nameError = props.touched.name && props.errors.name;
        const emailError = props.touched.email && props.errors.email;
        const phoneError = props.touched.phone && props.errors.phone;
        const passwordError = props.touched.password && props.errors.password;
        // const recaptchaError = props.touched.recaptcha && props.errors.recaptcha;

        return (
          <Form
            name='business-signup'
            method='post'
            data-netlify='true'
            // data-netlify-recaptcha='true'
            data-netlify-honeypot='bot-field'
            onSubmit={props.handleSubmit}
            className={classes.form}
          >
            <TextField
              required
              id='businessname'
              label='Business Name'
              type='text'
              name='businessname'
              aria-label='businessname'
              error={Boolean(businessNameError)}
              onChange={handleChange('businessname', props)}
              onBlur={props.handleBlur}
              value={props.values.businessname}
              helperText={businessNameError}
              className={classes.textField}
            />
            {type === 'demo' ? (
              <TextField
                required
                id='name'
                label='Your Name'
                type='text'
                name='name'
                aria-label='name'
                error={Boolean(nameError)}
                onChange={handleChange('businessname', props)}
                onBlur={props.handleBlur}
                value={props.values.name}
                helperText={nameError}
                className={classes.textField}
              />
            ) : null}
            <TextField
              required
              id='email'
              label='Email'
              aria-label='email'
              type='email'
              name='email'
              error={Boolean(emailError)}
              onChange={handleChange('email', props)}
              onBlur={props.handleBlur}
              value={props.values.email}
              helperText={emailError}
              className={classes.textField}
            />
            <TextField
              required
              label='Phone Number'
              aria-label='phone number'
              id='phone'
              type='tel'
              name='phone'
              error={Boolean(phoneError)}
              onChange={handleChange('phone', props)}
              onBlur={(e: any) => {
                props.handleBlur(e);
                const { value } = e.target;
                if (!props.errors.phone && value.trim()) {
                  const pn = parsePhoneNumberFromString(value, 'US');
                  if (pn?.isValid()) {
                    formContext.updateValue('phone', value);
                    props.setFieldValue('phone', pn.formatNational());
                  }
                }
              }}
              value={props.values.phone}
              helperText={phoneError}
              className={classes.textField}
            />
            <TextField
              required
              label='Password'
              aria-label='password'
              id='password'
              type='password'
              name='password'
              // placeholder=''
              error={Boolean(passwordError)}
              onChange={handleChange('password', props)}
              onBlur={props.handleBlur}
              value={props.values.password}
              helperText={passwordError}
              className={classes.textField}
            />
            {/* {props.values.businessname && props.values.email && props.values.phone && (
              <div className={classes.recaptchaField}>
                <FastField
                  component={Recaptcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  name='recaptcha'
                  onChange={(value: any) => props.setFieldValue('recaptcha', value)}
                />
                {Boolean(recaptchaError) && <p className={classes.error}>{recaptchaError}</p>}
              </div>
            )} */}
            <div className={classes.center}>
              <LoadingButton
                loading={props.isSubmitting}
                color='secondary'
                variant='contained'
                type='submit'
                className={classes.button}
              >
                {type === 'demo' ? 'Request Demo' : 'Create Account'}
              </LoadingButton>
              {errorOnSubmit && (
                <div>
                  <p className={classes.error}>{errorOnSubmit}</p>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const getErrorMessageByStatus = (response: Response) => {
  switch (response.status) {
    case 400:
      return 'The email already has already been used';
    case 403:
      return 'Invalid activation code';
    default:
      return DEFAULT_ERR_MSG;
  }
};

export default BusinessForm;
