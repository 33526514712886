import { Paper, makeStyles, createStyles, Theme, PaperProps } from '@material-ui/core';
import GiftCard from 'giftcards/common/types/GiftCard';
import React from 'react';
import QRCode from 'giftcards/assets/qr.png';
import GiftCardOffering from 'giftcards/common/types/GiftCardOffering';

interface OfferingPreviewProps extends PaperProps {
  offering: Partial<GiftCardOffering>;
  balanceType: 'original' | 'current';
  giftCard?: Partial<GiftCard>;
}

const OfferingPreview: React.FC<OfferingPreviewProps> = props => {
  const classes = useStyles();
  const { offering, giftCard, balanceType, ...paperProps } = props;
  const logoUrl = offering.logoUrl?.trim();

  return (
    <Paper
      {...paperProps}
      className={classes.root}
      style={{ backgroundColor: offering.backgroundColor, color: offering.fontColor }}
    >
      <div className={classes.pill}>Preview</div>
      <div className={classes.content}>
        <div className={classes.titleRow}>
          <div className={classes.logo}>
            {logoUrl && <img src={logoUrl} className={classes.logoImg} alt='logo' />}
            {offering.logoText && <span>{offering.logoText}</span>}
            {!logoUrl && !offering.logoText && <span className={classes.placeholder}>Logo Here</span>}
          </div>
          <div>
            <div className={classes.originalAmt}>{balanceType === 'original' ? 'Orig. Value' : 'Balance'}</div>
            <div className={classes.amount}>
              {offering?.currency_symbol || '$'}
              {(balanceType === 'original' ? props.giftCard?.initial_amount : props.giftCard?.remaining_balance) || 100}
            </div>
          </div>
        </div>
        <div className={classes.title}>{offering.title}</div>
        <div className={classes.cardData}>Code: {giftCard?.short_code || 'abC123'}</div>
        <div className={classes.cardData}>
          {giftCard?.purchaser_details?.recipient_name || giftCard?.purchaser_details?.name || 'James Smith'}
        </div>
        <div className={classes.description}>{offering.description}</div>
      </div>
      <div className={classes.qr}>
        <div className={classes.qrContainer}>
          <img src={QRCode} className={classes.qrImg} alt='qr code' />
        </div>
      </div>
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '0.5rem 1rem',
      width: '310px',
      height: '450px',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif'
    },
    content: {
      flexGrow: 0,
      flexShrink: 0,
      display: 'flex',
      flexDirection: 'column'
    },
    qr: {
      flexGrow: 1,
      flexShrink: 1,
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      paddingTop: '2rem'
    },
    titleRow: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      width: '100%'
    },
    amount: {
      flexShrink: 0,
      flexGrow: 0,
      fontWeight: 400,
      fontSize: '18px'
    },
    originalAmt: {
      fontWeight: 600,
      fontSize: '0.55rem',
      marginTop: '-3px',
      textTransform: 'uppercase'
    },
    title: {
      fontSize: '3.1rem',
      fontWeight: 300,
      paddingTop: '1.2rem',
      lineHeight: '2.5rem'
    },
    cardData: {
      fontWeight: 300,
      fontSize: '15px'
    },
    qrContainer: {
      height: '134px',
      width: '134px',
      backgroundColor: '#FFF',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    qrImg: {
      width: '112px',
      height: '112px'
    },
    logo: {
      'fontSize': '1.25rem',
      'lineHeight': '2.3rem',
      'display': 'flex',
      'alignItems': 'center',
      '& span': {
        fontWeight: 600,
        fontSize: '15px'
      },
      '& > img + span': {
        marginLeft: theme.spacing(1)
      }
    },
    logoImg: {
      maxHeight: '45px',
      maxWidth: '55px',
      objectFit: 'contain',
      objectPosition: 'left'
    },
    pill: {
      top: 0,
      fontSize: '0.75rem',
      color: '#FFF',
      fontWeight: 700,
      marginRight: '-43px',
      right: '50%',
      padding: '0.25rem 0.75rem',
      position: 'absolute',
      borderRadius: '5px',
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      textTransform: 'uppercase',
      backgroundColor: '#8e8e8e94'
    },
    description: {
      fontSize: '15px',
      fontWeight: 300,
      paddingTop: '2rem'
    },
    paneauLogo: {
      height: '19px',
      marginTop: '3px',
      marginLeft: '5px'
    },
    placeholder: {
      color: '#ccc'
    }
  })
);

export default OfferingPreview;
